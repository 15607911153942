import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useAlert } from "components/alert/useAlert";
import { useApi } from "components/api/useApi";
import { useSchema } from "components/api/useSchema";
import { useGet } from "components/api/useGet";
import { handleApiError } from "services";
import { FormBase } from "components/forms/FormBase";
import { FormFields } from "components/forms/FormFields";
import uiSchema from "./uiSchema.json";

// Independent component for the form so that it can be used in multiple places
// e.g. new farm page, new sampling event page, farm edit page
export const FarmDataForm = ({ farmId, isEditMode = false }) => {
  const alert = useAlert();
  const history = useHistory();
  const { schema, isLoading } = useSchema("/farm");
  const { data: existingData, mutate } = useGet(
    !Number.isNaN(Number(farmId)) ? `/farm/${farmId}` : null
  );

  const [apiState, setRequest] = useApi();
  const onSubmit = (formData) => {
    const data = {
      ...existingData,
      ...formData,
    };
    alert.clear();
    if (existingData) {
      // Data exists: Update
      setRequest({
        path: `/farm/${existingData.id}`,
        options: {
          method: "PUT",
          data,
        },
      });
    } else {
      // Data doesn't exist: Add
      setRequest({
        path: "/farm",
        options: {
          method: "POST",
          data,
        },
      });
    }
  };

  useEffect(() => {
    if (apiState.isLoading) {
      return;
    } else if (apiState.isError) {
      handleApiError({ apiState, alert });
    } else if (apiState.data?.id) {
      if (existingData) {
        alert.set({
          message: "Farm is updated.",
          variant: "success",
        });
        mutate(apiState.data);
        history.push(`/farms/${apiState.data.id}`);
      } else {
        history.push(`/farms/${apiState.data.id}/detail/edit`);
        alert.set({
          message: "Farm is added. Now please add details.",
          variant: "success",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiState]);

  return isLoading ? (
    <div>Loading farm entry form...</div>
  ) : (
    <FormBase
      isEditMode={isEditMode}
      submitButtonLabel={existingData ? "Update" : "Add"}
      onSubmit={onSubmit}
      isSubmitError={apiState.isError}
    >
      <fieldset disabled={!isEditMode}>
        <legend>Business info</legend>
        <FormFields
          uiSchemaSet={uiSchema.business}
          fieldSchemaAll={schema}
          defaultValueAll={existingData}
        />
      </fieldset>

      <fieldset disabled={!isEditMode}>
        <legend>Contact</legend>
        <FormFields
          uiSchemaSet={uiSchema.contact}
          fieldSchemaAll={schema}
          defaultValueAll={existingData}
        />
      </fieldset>
    </FormBase>
  );
};

FarmDataForm.propTypes = {
  farmId: PropTypes.string,
  isEditMode: PropTypes.bool,
};
