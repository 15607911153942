import React from "react";
import {
  useParams,
  useRouteMatch,
  Switch,
  Route,
} from "react-router-dom";
import { FarmDataForm } from "components/forms/FarmDataForm";

export const BasicTab = () => {
  const { farmId } = useParams(); // GET farm data with this id
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <FarmDataForm farmId={farmId} isEditMode={false} />
      </Route>
      <Route exact path={`${path}/edit`}>
        <FarmDataForm farmId={farmId} isEditMode />
      </Route>
    </Switch>
  );
};
