import React from "react";
import { FarmDataForm } from "components/forms/FarmDataForm";

export const NewFarmPage = () => {
  return (
    <div>
      <h1>Add a New Farm</h1>
      <FarmDataForm isEditMode />
    </div>
  );
};
