import React from "react";
import PropTypes from "prop-types";
import Badge from "react-bootstrap/Badge";
import { SAMPLING_EVENT_STATUS } from "services/constants";
import "./index.scss";

export const SamplingEventStatusBadge = ({ status }) => (
  <Badge
    variant={status === "o" ? "primary" : "danger"}
    className="badge-status mr-2"
  >
    {SAMPLING_EVENT_STATUS[status]}
  </Badge>
);

SamplingEventStatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
};
