import React from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Select from "react-select";
import { Pagination } from "components/Pagination";
import { SamplingEventCard } from "./SamplingEventCard";
import { useGet } from "components/api/useGet";
import { reactSelectTheme } from "services";

export const SamplingEventListPage = () => {
  const history = useHistory();
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);
  const currentPage = Number(urlParams.get("page")) || 1;
  urlParams.set("ordering", "-date");

  // Show open events by default
  if (!urlParams.get("status")) {
    urlParams.set("status", "o");
  }

  // Hack: to show all, backend request should not contain status, but simply deleting it
  // results in setting "o", so use "all" option only as a flag in the frontend.
  const backendParams = new URLSearchParams(urlParams.toString());
  if (backendParams.get("status") === "all") {
    backendParams.delete("status");
  }
  const { data: samplingEvents, isLoading: isLoadingSamplingEvents } = useGet(
    `/sampling-event?${backendParams.toString()}`
  );

  let maxPage = 0;
  let showing = "-";
  const pageSize = samplingEvents?.page_size || 10;
  if (samplingEvents?.count) {
    maxPage = Math.ceil(samplingEvents.count / pageSize);
    showing = `${pageSize * (currentPage - 1) + 1} - ${Math.min(
      pageSize * currentPage,
      samplingEvents.count
    )}`;
  }

  const { data: farms, isLoading: isLoadingFarms } = useGet(
    "/farm?ordering=name"
  );
  const farmOptions =
    Array.isArray(farms?.results) && farms.results.length > 0
      ? farms.results.map((farm) => ({
          value: farm.id,
          label: farm.name,
        }))
      : [];
  const defaultValue =
    farmOptions.find(
      (option) => option.value === Number(urlParams.get("farm"))
    ) || undefined;
  const onChangeFarm = (selected, option) => {
    if (!selected && option.action !== "clear") {
      return null;
    }
    if (option.action === "clear") {
      urlParams.delete("farm");
    }
    if (selected) {
      urlParams.set("farm", selected.value);
    }
    urlParams.set("page", 1);
    history.push({ search: `?${urlParams.toString()}` });
  };

  return (
    <React.Fragment>
      <h1 className="mb-3">Sampling Events</h1>
      <div className="d-flex justify-content-between align-items-baseline mb-3">
        <Button onClick={() => history.push("/sampling-events/new")}>
          Add a new sampling event
        </Button>
        <Link to="/farms">Edit farms</Link>
      </div>

      {isLoadingFarms ? (
        <div>Loading...</div>
      ) : (
        <Select
          options={farmOptions}
          defaultValue={defaultValue}
          onChange={onChangeFarm}
          isClearable
          className="mb-3"
          placeholder="Filter by farm"
          aria-label="Filter by farm"
          theme={reactSelectTheme}
        />
      )}

      {isLoadingSamplingEvents ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-baseline">
            <div className="text-muted">
              Showing {showing} of {samplingEvents?.count || 0} results
            </div>

            <Nav variant="tabs" className="mb-0">
              <Nav.Item>
                <Nav.Link
                  active={urlParams.get("status") === "o"}
                  onClick={() => {
                    urlParams.set("status", "o");
                    urlParams.set("page", 1);
                    history.push({ search: `?${urlParams.toString()}` });
                  }}
                >
                  Open
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  active={urlParams.get("status") === "c"}
                  onClick={() => {
                    urlParams.set("status", "c");
                    urlParams.set("page", 1);
                    history.push({ search: `?${urlParams.toString()}` });
                  }}
                >
                  Closed
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  active={urlParams.get("status") === "all"}
                  onClick={() => {
                    // Hack: use "all" as a frontend-only flag
                    urlParams.set("status", "all");
                    urlParams.set("page", 1);
                    history.push({ search: `?${urlParams.toString()}` });
                  }}
                >
                  All
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          <div className="mb-4">
            {!samplingEvents || Object.entries(samplingEvents).length === 0 ? (
              <div>Loading...</div>
            ) : (
              Array.isArray(samplingEvents.results) &&
              samplingEvents.results.length > 0 &&
              samplingEvents.results.map((samplingEvent) => (
                <SamplingEventCard
                  key={samplingEvent.id}
                  samplingEvent={samplingEvent}
                />
              ))
            )}
          </div>

          <Pagination maxPage={maxPage} currentPage={currentPage} />
        </>
      )}
    </React.Fragment>
  );
};
