import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export const NoMatchPage = () => {
  return (
    <>
      <Helmet>
        <title>Page Not Found | DietDecoder</title>
      </Helmet>

      <p>Sorry, the page you requested doesn't exist.</p>
      <Link to="/">Go to dashboard</Link>
    </>
  );
};
