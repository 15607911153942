import React from "react";
import Table from "react-bootstrap/Table";
import { useGet } from "components/api/useGet";

const elementSymbolMap = {
  calcium: "Ca",
  chloride: "Cl",
  magnesium: "Mg",
  phosphorus: "P",
  potassium: "K",
  sodium: "Na",
  sulphur: "S",
  cobalt: "Co",
  copper: "Cu",
  iodine: "I",
  iron: "Fe",
  manganese: "Mn",
  molybdenum: "Mo",
  selenium: "Se",
  zinc: "Zn",
};

function getPrescription(element) {
  // Toxicity requires all amounts to be in same unit
  // amount ingested are always in the element's unit, so need to scale the prescription
  const prescription = element.prescribed
  if (prescription.weight_unit === "g" && element.mass_unit === "mg") {
    return prescription.amount * 1000
  }
  else if (prescription.weight_unit === "mg" && element.mass_unit === "g") {
    return prescription.amount / 1000
  }
  return prescription.amount
}

function getToxicity(element, DMI) {
  const aggregated_ingested = (getPrescription(element) + element.total_ingested)/DMI
  switch (element.label) {
    case "Magnesium":
    case "Sulphur":
      return aggregated_ingested > 5
    case "Selenium":
      return aggregated_ingested > 0.50
    case "Iodine":
      return aggregated_ingested > 50
    case "Copper":
      return aggregated_ingested > 40
    case "Cobalt":
      return aggregated_ingested > 30
    case "Manganese":
      return aggregated_ingested > 400
    case "Zinc":
      return aggregated_ingested > 600
    default:
      return
  }
}
export const PrescriptionTable = ({ reportData, analysisData, DMI }) => {
  // `analysisData.prescription` is prescription id
  // found in `sampling-event.[calc_type]_dietary_analysis`
  const { data: prescription, isLoading: isLoadingPrescription } = useGet(
    analysisData?.prescription
      ? `/prescription/${analysisData.prescription}`
      : null
  );

  const prescriptionTableData = [
    reportData.ca_percent,
    reportData.cl_percent,
    reportData.mg_percent,
    reportData.p_percent,
    reportData.k_percent,
    reportData.na_percent,
    reportData.s_percent,
    reportData.co_ppm,
    reportData.cu_ppm,
    reportData.i_ppm,
    reportData.fe_ppm,
    reportData.mn_ppm,
    reportData.mo_ppm,
    reportData.se_ppm,
    reportData.zn_ppm,
  ].map((element) => {
    const symbol = elementSymbolMap[element.label.toLowerCase()];
    return {
      label: element.label,
      mass_unit: element.mass_unit,
      element_required: element.element_required,
      prescribed: prescription?.aggregates[symbol],
      total_ingested: element.total_ingested,
    };
  });

  return isLoadingPrescription ? (
    <div>Loading...</div>
  ) : (
    <Table hover size="sm" className="prescription-table mb-4">
      <thead>
        <tr>
          <th></th>
          <th>Required amount</th>
          <th>Prescribed amount</th>
          <th style={{width: "30%"}}>Toxicity check</th>
        </tr>
      </thead>
      <tbody>
        {prescriptionTableData.map((element) => {
          const required = element.element_required?.supplement_value;
          const prescribed = element.prescribed?.amount;
          const unit = element.prescribed?.weight_unit;
          const toxicityRiskLabel = getToxicity(element, DMI) ? (
            <span className="text-danger">
              Toxicity Risk – Reduce Supplemental Amount
            </span>
          ) : (
            "-"
          );
          if (required <= 0 && prescribed <= 0) return null;
          return (
            <tr key={element.label}>
              <th>
                {element.label} ({element.mass_unit})
              </th>
              <td>{required?.toFixed(2)}</td>
              <td>{prescribed} {unit}</td>
              <td>{toxicityRiskLabel}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
