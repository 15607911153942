export const SAMPLING_EVENT_STATUS = {
  o: "Open",
  c: "Closed",
};

// Machine readable for CSS classes.
export const MINERAL_STATUS = {
  d: "deficient",
  i: "insufficient",
  s: "satisfactory",
  e: "excessive",
  t: "toxic",
  h: "highrisk", // For non-mineral parameters instead of "toxic".
};

// Human readable for printable labels.
export const MINERAL_STATUS_LABELS = {
  d: "Deficient",
  i: "Insufficient",
  s: "Satisfactory",
  e: "Excessive",
  t: "Toxic",
  h: "High Risk", // For non-mineral parameters instead of "toxic".
}

export const PRODUCT_ELEMENT_MAP = [
  { key: "ca_percent", symbol: "Ca", element: "Calcium" },
  { key: "cl_percent", symbol: "Cl", element: "Chlorine" },
  { key: "mg_percent", symbol: "Mg", element: "Magnesium" },
  { key: "mg_coa", symbol: "Mg CoA" },
  { key: "p_percent", symbol: "P", element: "Phosphorus" },
  { key: "k_percent", symbol: "K", element: "Potassium" },
  { key: "na_percent", symbol: "Na", element: "Sodium" },
  { key: "s_percent", symbol: "S", element: "Sulphur" },
  { key: "co_percent", symbol: "Co", element: "Cobalt" },
  { key: "cu_percent", symbol: "Cu", element: "Copper" },
  { key: "i_percent", symbol: "I", element: "Iodine" },
  { key: "fe_percent", symbol: "Fe", element: "Iron" },
  { key: "mn_percent", symbol: "Mn", element: "Manganese" },
  { key: "mo_percent", symbol: "Mo", element: "Molybdenum" },
  { key: "se_percent", symbol: "Se", element: "Selenium" },
  { key: "zn_percent", symbol: "Zn", element: "Zinc" },
];
