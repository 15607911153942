import React from "react";
import { ElementProfile } from "./ElementProfile";

const nonMinerals = [
  {
    name: "Crude Protein",
    reportKey: "cp_percent",
    code: "protein",
  },
  {
    name: "Neutral Detergent Fibre",
    reportKey: "ndf_percent",
    code: "ndf",
  },
  {
    name: "Sugars",
    reportKey: "sugar_percent",
    code: "sugars",
  },
  {
    name: "Starch",
    reportKey: "starch_percent",
    code: "starch",
  },
  {
    name: "Fat",
    reportKey: "fat_percent",
    code: "fat",
  },
  {
    name: "Metabolic Energy (MJ/kgDM)",
    reportKey: "me_mjkg",
    code: "metabolic_energy",
  },
];

export const NonMinerals = ({ report, samplingEventType, edit = false }) => {
  return nonMinerals.map((item) => (
    <ElementProfile
      key={item.reportKey}
      item={item}
      category="nonmineral"
      report={report}
      unit="%DM"
      samplingEventType={samplingEventType}
      edit={edit}
    />
  ));
};
