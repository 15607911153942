export const NON_MINERALS = [
  {
    name: "Non Mineral Parameters",
    symbol: "N/A",
    status: "nonmineral_summary",
    comment: "nonmineral_summary_comment",
  },
];

export const MACRO_MINERALS = [
  {
    name: "Phosphorus (P)",
    symbol: "P",
    status: "p_summary",
    comment: "p_summary_comment",
  },
  {
    name: "Calcium (Ca)",
    symbol: "Ca",
    status: "ca_summary",
    comment: "ca_summary_comment",
  },
  {
    name: "Potassium (K)",
    symbol: "K",
    status: "k_summary",
    comment: "k_summary_comment",
  },
  {
    name: "Magnesium (Mg)",
    symbol: "Mg",
    status: "mg_summary",
    comment: "mg_summary_comment",
  },
  {
    name: "Sulphur (S)",
    symbol: "S",
    status: "s_summary",
    comment: "s_summary_comment",
  },
  {
    name: "Sodium (Na)",
    symbol: "Na",
    status: "na_summary",
    comment: "na_summary_comment",
  },
];

export const TRACE_MINERALS = [
  {
    name: "Copper (Cu)",
    symbol: "Cu",
    status: "cu_summary",
    comment: "cu_summary_comment",
  },
  {
    name: "Zinc (Zn)",
    symbol: "Zn",
    status: "zn_summary",
    comment: "zn_summary_comment",
  },
  {
    name: "Cobalt (Co)",
    symbol: "Co",
    status: "co_summary",
    comment: "co_summary_comment",
  },
  {
    name: "Selenium (Se)",
    symbol: "Se",
    status: "se_summary",
    comment: "se_summary_comment",
  },
  {
    name: "Iodine (I)",
    symbol: "I",
    status: "i_summary",
    comment: "i_summary_comment",
  },
];
