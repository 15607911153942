import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Alert from "react-bootstrap/Alert";
import { useGet } from "components/api/useGet";
import { FileUploadForm } from "components/forms/FileUploadForm";
import { getDisplayDateTime, sortFileCreatedDesc } from "services";
import { Section } from "./Section";
import { AddDietaryCommentaryButton } from "./AddDietaryCommentaryButton";

const UploadForm = ({ samplingEvent, mutate }) => (
  <FileUploadForm
    path={`/sampling-event/${samplingEvent.id}/diet-commentary-report`}
    label="Select Dietary Commentary Report PDF file"
    onSuccess={mutate}
    successMessage="Dietary Commentary Report PDF is uploaded."
  />
);

export const DietaryCommentarySection = ({ samplingEvent, mutate }) => {
  // If analysis report exists, it's ready to create action summary report
  const analysisReports = [].concat(
    samplingEvent.dairy_dietary_analysis,
    samplingEvent.youngstock_dietary_analysis,
    samplingEvent.replacement_stock_dietary_analysis,
    samplingEvent.sheep_dietary_analysis,
    samplingEvent.lamb_dietary_analysis
  );
  const analysisExists = analysisReports.length > 0;

  // Fetch existing dietary commentary report
  const {
    data: report,
    isLoading,
    error,
  } = useGet(
    `/reports/dietary-commentary-report?sampling_event=${samplingEvent.id}`
  );
  const reportExists = report?.results?.length > 0;

  const uploadedPdfFiles = samplingEvent.diet_commentary_report.length
    ? samplingEvent.diet_commentary_report.sort(sortFileCreatedDesc)
    : [];

  return (
    <Section isDone={samplingEvent.diet_commentary_report.length > 0}>
      <h2>Dietary Commentary Report</h2>
      <p>
        This will be marked done when PDF is uploaded. To generate PDF, view
        online, print (Ctrl + P), and save as PDF.
      </p>

      {!analysisExists && (
        <Alert variant="warning">
          To edit, please create at least one Dietary Analysis Report.
        </Alert>
      )}
      {analysisExists && isLoading && <div>Loading...</div>}
      {analysisExists && !isLoading && error && (
        <div>Failed to load Dietary Commentary Report...</div>
      )}
      {analysisExists && !isLoading && !error && !reportExists && (
        <AddDietaryCommentaryButton samplingEvent={samplingEvent} />
      )}
      {analysisExists && !isLoading && !error && reportExists && (
        <>
          <div>
            <Link to={`/sampling-events/${samplingEvent.id}/commentary/edit`}>
              Edit
            </Link>
          </div>
          <div>
            <Link to={`/sampling-events/${samplingEvent.id}/commentary`}>
              View online
            </Link>
          </div>

          {uploadedPdfFiles.length <= 0 && (
            <div className="mt-3">
              <UploadForm samplingEvent={samplingEvent} mutate={mutate} />
            </div>
          )}
          {uploadedPdfFiles.length > 0 && (
            <div className="mt-3">
              <div>View uploaded PDF</div>
              <ul className="list-style-disc">
                {uploadedPdfFiles.map((report, i) => (
                  <li key={report.id}>
                    <a href={report.file}>
                      {getDisplayDateTime(report.created)}
                      {uploadedPdfFiles.length > 1 && i === 0 && " - Latest"}
                    </a>
                  </li>
                ))}
              </ul>
              <Accordion className="mt-3">
                <Accordion.Toggle
                  as="a"
                  href="#"
                  eventKey="0"
                  onClick={(e) => e.preventDefault()}
                >
                  Upload a new file
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <UploadForm samplingEvent={samplingEvent} mutate={mutate} />
                </Accordion.Collapse>
              </Accordion>
            </div>
          )}
        </>
      )}
    </Section>
  );
};

DietaryCommentarySection.propTypes = {
  samplingEvent: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
};
