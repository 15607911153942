import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useAlert } from "components/alert/useAlert";
import { useApi } from "components/api/useApi";
import { useSchema } from "components/api/useSchema";
import { useGet } from "components/api/useGet";
import { handleApiError } from "services";
import { FormBase } from "components/forms/FormBase";
import { FormFields } from "components/forms/FormFields";
import uiSchema from "./uiSchema.json";

export const FarmSheepForm = ({ farmId, isEditMode = false }) => {
  const alert = useAlert();
  const history = useHistory();
  const { schema, isLoading: isLoadingSchema } = useSchema("/sheep/farm");
  const { data, isLoading: isLoadingGet, mutate } = useGet(
    `/sheep/farm?farm=${farmId}`
  );
  const existingData = data?.results?.[0];

  const [apiState, setRequest] = useApi();
  const onSubmit = (formData) => {
    const data = {
      ...existingData,
      ...formData,
      farm: farmId,
    };
    alert.clear();
    if (existingData) {
      // Data exists: Update
      setRequest({
        path: `/sheep/farm/${existingData.id}`,
        options: {
          method: "PUT",
          data,
        },
      });
    } else {
      // Data doesn't exist: Add
      setRequest({
        path: `/sheep/farm?farm=${farmId}`,
        options: {
          method: "POST",
          data,
        },
      });
    }
  };

  useEffect(() => {
    if (apiState.isLoading) {
      return;
    } else if (apiState.isError) {
      handleApiError({ apiState, alert });
    } else if (apiState.data?.id) {
      alert.set({
        message: `Sheep info is ${existingData ? "updated" : "added"}.`,
        variant: "success",
      });
      mutate(apiState.data);
      history.push(`/farms/${farmId}/sheep`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiState]);

  return isLoadingSchema || isLoadingGet ? (
    <div>Loading form...</div>
  ) : (
    <FormBase
      isEditMode={isEditMode}
      submitButtonLabel={existingData ? "Update" : "Add"}
      onSubmit={onSubmit}
      isSubmitError={apiState.isError}
    >
      <fieldset disabled={!isEditMode}>
        <legend>Farm technology</legend>
        <FormFields
          uiSchemaSet={uiSchema.farm_technology}
          fieldSchemaAll={schema}
          defaultValueAll={existingData}
        />
      </fieldset>
    </FormBase>
  );
};

FarmSheepForm.propTypes = {
  farmId: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool,
};
