import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ProvideAuth } from "components/auth/useAuth";
import { ProvideAlert } from "components/alert/useAlert";
import { PrivateRoute } from "components/auth/PrivateRoute";
import { PageFrame } from "components/PageFrame";
import { LoginPage } from "components/auth/LoginPage";
import { SamplingEventListPage } from "pages/sampling-events/SamplingEventListPage";
import { SamplingEventPage } from "pages/sampling-events/SamplingEventPage";
import { NewSamplingEventPage } from "pages/sampling-events/NewSamplingEventPage";
import { QuestionnairePage } from "pages/sampling-events/QuestionnairePage";
import { DietaryAnalysisReportPage } from "pages/sampling-events/DietaryAnalysisReport";
import { DietaryCommentaryReportPage } from "pages/sampling-events/DietaryCommentaryReport/ViewPage";
import { DietaryCommentaryReportEditPage } from "pages/sampling-events/DietaryCommentaryReport/EditPage";
import { ActionSummaryReportPage } from "pages/sampling-events/ActionSummaryReport/ViewPage";
import { ActionSummaryReportEditPage } from "pages/sampling-events/ActionSummaryReport/EditPage";
import { FarmListPage } from "pages/farms/FarmListPage";
import { FarmPage } from "pages/farms/FarmPage";
import { NewFarmPage } from "pages/farms/NewFarmPage";
import { NoMatchPage } from "pages/NoMatchPage";

function App() {
  return (
    <Router>
      <ProvideAlert>
        <ProvideAuth>
          <PageFrame>
            <Switch>
              <Route path="/login">
                <LoginPage />
              </Route>
              <PrivateRoute exact path="/sampling-events">
                <SamplingEventListPage />
              </PrivateRoute>
              <PrivateRoute exact path="/sampling-events/new">
                <NewSamplingEventPage />
              </PrivateRoute>
              <PrivateRoute exact path="/sampling-events/:samplingEventId">
                <SamplingEventPage />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/sampling-events/:samplingEventId/questionnaire"
              >
                <QuestionnairePage />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/sampling-events/:samplingEventId/questionnaire/edit"
              >
                <QuestionnairePage isEditMode />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/sampling-events/:samplingEventId/analysis/:stockClass"
              >
                <DietaryAnalysisReportPage />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/sampling-events/:samplingEventId/commentary/edit"
              >
                <DietaryCommentaryReportEditPage />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/sampling-events/:samplingEventId/commentary"
              >
                <DietaryCommentaryReportPage />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/sampling-events/:samplingEventId/action/edit"
              >
                <ActionSummaryReportEditPage />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/sampling-events/:samplingEventId/action"
              >
                <ActionSummaryReportPage />
              </PrivateRoute>
              <PrivateRoute exact path="/farms">
                <FarmListPage />
              </PrivateRoute>
              {/* TODO: Limit /farms/new to admins */}
              <PrivateRoute exact path="/farms/new">
                <NewFarmPage />
              </PrivateRoute>
              <PrivateRoute path="/farms/:farmId">
                <FarmPage />
              </PrivateRoute>
              <PrivateRoute exact path="/">
                <Redirect to="/sampling-events" />
              </PrivateRoute>
              <Route path="*">
                <NoMatchPage />
              </Route>
            </Switch>
          </PageFrame>
        </ProvideAuth>
      </ProvideAlert>
    </Router>
  );
}

export default App;
