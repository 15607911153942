import React from "react";
import PropTypes from "prop-types";
import { FormField } from "components/forms/FormField";
import { useGet } from "components/api/useGet";

export const PrescriptionChoices = ({
  product,
  stockClass,
  animalType,
  prescription,
  isEdit = false,
}) => {
  // Fetch product treatment multipliers
  const {
    data: multipliersRaw,
    isLoading,
    error,
  } = useGet(
    `/reports/product-treatment-multipliers?animal_type=${animalType}`
  );
  const multipliers = multipliersRaw?.results;

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Failed to load product data.</div>;
  }

  // Get multipliers for this product
  const productMultipliers = multipliers.filter(
    (multiplier) => multiplier.product === product.productid
  );
  const hasMultipliers = productMultipliers.length > 0;

  const treatmentOptions = product.treatment_options?.[stockClass];
  const treatmentid = treatmentOptions?.treatmentid;
  const choices = treatmentOptions?.choices ?? [];

  const { amount: baseAmount, unit } = product.base_product_amounts[stockClass];

  // Selected values: Convert from
  //   "choices": {
  //     "is": true,
  //     "mi": false,
  //     "df": true,
  //     "dp": false,
  //     "wt": false
  //   }
  // to
  //   ["is", "df"]
  const selectedValues = [];
  Object.keys(choices).forEach((key) => {
    if (choices[key]) {
      selectedValues.push(key);
    }
  });

  if (isEdit) {
    // Prefix treatmentid with "id-" so that it doesn't become an array
    return (
      <>
        <div>{prescription}</div>
        {hasMultipliers && treatmentid && choices && (
          <FormField
            fieldName={`product-treatment.id-${treatmentid}`}
            schema={{
              type: "multiple_choice",
              choices: productMultipliers.map((multiplier) => ({
                id: multiplier.method,
                name: `${
                  Number(baseAmount) * Number(multiplier.multiplier)
                }${unit} ${multiplier.method_label}`,
              })),
            }}
            defaultValue={selectedValues}
          />
        )}
      </>
    );
  } else {
    return hasMultipliers ? (
      <>
        {selectedValues.length <= 0 && (
          <div>
            Treatment method not selected.
            <br />
            Please select in edit page.
          </div>
        )}
        {selectedValues.length > 0 &&
          selectedValues.map((selected, i) => {
            const multiplier = productMultipliers.find(
              (multiplier) => multiplier.method === selected
            );
            const amount = Number(baseAmount) * Number(multiplier.multiplier);
            const suffix = i < selectedValues.length - 1 ? ", OR" : "";
            return (
              <div key={selected}>
                {amount}
                {unit} {multiplier.method_label}
                {suffix}
              </div>
            );
          })}
      </>
    ) : (
      <div>{prescription}</div>
    );
  }
};

PrescriptionChoices.propTypes = {
  product: PropTypes.object.isRequired,
  stockClass: PropTypes.string.isRequired,
  animalType: PropTypes.string.isRequired,
  prescription: PropTypes.string.isRequired,
};
