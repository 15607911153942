import React from "react";
import Tab from "react-bootstrap/Tab";
import {
  useParams,
  useRouteMatch,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { useGet } from "components/api/useGet";
import { BasicTab } from "./BasicTab";
import { DetailTab } from "./DetailTab";
import { DairyTab } from "./DairyTab";
import { BeefTab } from "./BeefTab";
import { SheepTab } from "./SheepTab";

export const FarmPage = () => {
  const { farmId } = useParams(); // GET farm data with this id
  const { path, url } = useRouteMatch();
  const { data: farm, isLoading, error } = useGet(`/farm/${farmId}`);

  return error ? (
    <div>Something went wrong...</div>
  ) : isLoading ? (
    <div>Loading farm data...</div>
  ) : (
    <React.Fragment>
      <h1>{farm.name}</h1>
      <Nav variant="tabs">
        <Nav.Item>
          <Nav.Link eventKey="basic" to={url} exact as={NavLink}>
            Basic info
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="detail" to={`${url}/detail`} as={NavLink}>
            Details
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="dairy" to={`${url}/dairy`} as={NavLink}>
            Dairy
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="beef" to={`${url}/beef`} as={NavLink}>
            Beef
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="sheep" to={`${url}/sheep`} as={NavLink}>
            Sheep
          </Nav.Link>
        </Nav.Item>
      </Nav>

      <Tab.Content>
        <Switch>
          <Route path={`${path}/detail`}>
            <DetailTab />
          </Route>
          <Route path={`${path}/dairy`}>
            <DairyTab />
          </Route>
          <Route path={`${path}/beef`}>
            <BeefTab />
          </Route>
          <Route path={`${path}/sheep`}>
            <SheepTab />
          </Route>
          <Route path={path}>
            <BasicTab />
          </Route>
        </Switch>
      </Tab.Content>
    </React.Fragment>
  );
};
