import React from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import { useGet } from "components/api/useGet";
import { getSamplingEventTitle } from "services";
import { SAMPLING_EVENT_STATUS } from "services/constants";
import "./index.scss";

export const SamplingEventCard = ({ samplingEvent }) => {
  const { data: farm, isLoading: isLoadingFarm } = useGet(
    samplingEvent.farm ? `/farm/${samplingEvent.farm}` : null
  );
  const title = getSamplingEventTitle(samplingEvent, farm);

  let progress = "Waiting for Lab Analysis";
  if (samplingEvent.lab_analysis?.length > 0) {
    progress = "Composing Reports";
  }
  if (
    samplingEvent.diet_commentary_report?.length > 0 &&
    samplingEvent.action_summary_report?.length > 0
  ) {
    progress = "Reports are Ready";
  }

  return isLoadingFarm ? (
    <div>Loading...</div>
  ) : (
    <Card bg={samplingEvent.status === "c" && "light"}>
      <Card.Body className="d-sm-flex justify-content-between">
        <div>
          <div className="sampling-event-card-title">
            <Link to={`/sampling-events/${samplingEvent.id}`}>{title}</Link>
          </div>
          <div className="sampling-event-card-secondary-info">
            #{samplingEvent.id} created by {samplingEvent.consultant}
          </div>
        </div>

        <div className="sampling-event-card-status">
          <div className="text-muted text-sm-right">
            {SAMPLING_EVENT_STATUS[samplingEvent.status]}
          </div>
          <div className="sampling-event-card-secondary-info text-sm-right">
            {progress}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

SamplingEventCard.propTypes = {
  samplingEvent: PropTypes.object.isRequired,
};
