import React, { useEffect } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Pagination } from "components/Pagination";
import { useAlert } from "components/alert/useAlert";
import { useGet } from "components/api/useGet";
import { useAuth } from "components/auth/useAuth";
import "./index.scss";

// Can this be fetched from Django?
const PAGE_SIZE = 100;

export const FarmListPage = () => {
  const history = useHistory();
  const location = useLocation();
  const alert = useAlert();
  const auth = useAuth();

  const urlParams = new URLSearchParams(location.search);
  const currentPage = Number(urlParams.get("page")) || 1;
  urlParams.append("ordering", "name");

  const { data, isLoading, error } = useGet(`/farm?${urlParams.toString()}`);
  const maxPage = data?.count ? Math.ceil(data.count / PAGE_SIZE) : 0;

  // Error handling
  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (error) {
      alert.set({
        message: "Failed to get farms. Please try again later.",
        variant: "danger",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, error]);

  return (
    <React.Fragment>
      <h1 className="mb-3">Farms</h1>
      {auth.session?.can_add_farm && (
        <div className="mb-3">
          <Button onClick={() => history.push("/farms/new")}>
            Add a new farm
          </Button>
        </div>
      )}

      <div className="farms-list mb-4">
        {isLoading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Something went wrong...</div>
        ) : (
          Array.isArray(data.results) &&
          data.results.length > 0 && (
            <ul>
              {data.results.map((farm) => (
                <li key={farm.name}>
                  <Link to={`/farms/${farm.id}`}>{farm.name}</Link>
                </li>
              ))}
            </ul>
          )
        )}
      </div>

      {!isLoading && <Pagination maxPage={maxPage} currentPage={currentPage} />}
    </React.Fragment>
  );
};
