import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { mutate } from "swr";
import { useApi } from "components/api/useApi";
import { useAlert } from "components/alert/useAlert";
import { handleApiError } from "services";

export const AddActionSummaryButton = ({ samplingEvent }) => {
  const alert = useAlert();
  const history = useHistory();
  const [apiState, setRequest] = useApi();
  const onClick = (e) => {
    e.preventDefault();
    const data = {
      sampling_event: samplingEvent.id,
    };
    setRequest({
      path: "/reports/action-summary-report",
      options: {
        method: "POST",
        data,
      },
    });
  };
  useEffect(() => {
    if (apiState.isLoading) {
      return;
    } else if (apiState.isError) {
      handleApiError({ apiState, alert });
    } else if (apiState.data) {
      // Bust cache
      mutate(
        `/reports/action-summary-report?sampling_event=${samplingEvent.id}`
      );
      // Go to commentary page
      history.push(`/sampling-events/${samplingEvent.id}/action/edit`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiState]);

  return <Button onClick={onClick}>Add Action Summary Report</Button>;
};
