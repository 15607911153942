import React from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { useFormContext } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./RitchTextArea.scss";

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ color: [] }],
    ["clean"],
  ],
};

export const RitchTextArea = ({ name, value, onChange, ...rest }) => {
  const { register } = useFormContext();

  return (
    <>
      <ReactQuill
        theme="snow"
        modules={modules}
        value={value}
        onChange={onChange}
        className="mb-3"
        {...rest}
      />
      <Form.Control
        as="textarea"
        name={name}
        ref={register()}
        value={value}
        onChange={() => {}}
        hidden
      />
    </>
  );
};

RitchTextArea.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
