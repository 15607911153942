import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./useAuth";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export function PrivateRoute({ children, ...rest }) {
  const auth = useAuth();
  return auth.loading ? (
    <div>Checking login status...</div>
  ) : (
    <Route
      {...rest}
      render={({ location }) =>
        auth.session ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
