import React from "react";
import Container from "react-bootstrap/Container";
import "./index.scss";

export const StickyBarBottom = ({ children }) => (
  <>
    <div className="spacer"></div>
    <div className="sticky-bar-bottom">
      <Container fluid="lg">{children}</Container>
    </div>
  </>
);
