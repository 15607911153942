import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { useHistory } from "react-router-dom";
import { mutate } from "swr";
import { useAlert } from "components/alert/useAlert";
import { useApi } from "components/api/useApi";
import { handleApiError } from "services";

export const AddDietaryAnalysisForm = ({ samplingEvent, options }) => {
  const alert = useAlert();
  const history = useHistory();
  const [selectedStockClass, setSelectedStockClass] = useState("");
  const [apiState, setRequest] = useApi();
  const onSubmit = (e) => {
    e.preventDefault();
    setRequest({
      path: `/reports/dietary-analysis-report/${samplingEvent.id}/${selectedStockClass}`,
      options: {
        method: "POST",
      },
    });
  };

  useEffect(() => {
    if (apiState.isLoading) {
      return;
    } else if (apiState.isError) {
      handleApiError({ apiState, alert });
    } else if (apiState.data) {
      alert.set({
        message: "Analysis is added.",
        variant: "success",
      });
      // Bust cache
      mutate(`/sampling-event/${samplingEvent.id}`);
      // Go to analysis page
      history.push(
        `/sampling-events/${samplingEvent.id}/analysis/${selectedStockClass}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiState]);

  return (
    <Form onSubmit={onSubmit}>
      <Form.Row className="align-items-start">
        <Form.Group as={Col} controlId="select-stock-class">
          <Form.Label className="sr-only">Add a stock class</Form.Label>
          <Form.Control
            as="select"
            value={selectedStockClass}
            onChange={(e) => setSelectedStockClass(e.target.value)}
            aria-describedby="add-stock-class-help"
          >
            <option value="">-- Add a stock class --</option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.display_name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Col className="flex-grow-0">
          <Button type="submit">Add</Button>
        </Col>
      </Form.Row>
    </Form>
  );
};

AddDietaryAnalysisForm.propTypes = {
  samplingEvent: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
};
