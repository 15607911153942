import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useParams, useHistory } from "react-router-dom";
import { mutate } from "swr";
import { useApi } from "components/api/useApi";
import { useAlert } from "components/alert/useAlert";
import { handleApiError } from "services";

export const DeleteAnalysisButton = () => {
  const { samplingEventId, stockClass } = useParams();
  const history = useHistory();
  const alert = useAlert();
  const [apiState, setRequest] = useApi();

  const onClick = (e) => {
    e.preventDefault();
    const confirmed = window.confirm(
      "Are you sure you want to delete the analysis?"
    );
    if (confirmed) {
      setRequest({
        path: `/reports/dietary-analysis-report/${samplingEventId}/${stockClass}`,
        options: {
          method: "DELETE",
        },
      });
    }
  };

  useEffect(() => {
    if (apiState.isLoading) {
      return;
    } else if (apiState.isError) {
      handleApiError({ apiState, alert });
    } else if (apiState.data) {
      alert.set({
        message: "Analysis is deleted.",
        variant: "success",
      });
      // Bust cache
      mutate(`/sampling-event/${samplingEventId}`);
      // Go to analysis page
      history.push(`/sampling-events/${samplingEventId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiState]);

  return (
    <Button variant="danger" className="mt-3" onClick={onClick}>
      Delete Analysis
    </Button>
  );
};
