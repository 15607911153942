import React from "react";
import Alert from "react-bootstrap/Alert";
import { useAlert } from "./useAlert";

export function AlertBanner() {
  const { message, variant, show, clear } = useAlert();
  return show ? (
    <Alert
      variant={variant}
      onClose={() => {
        clear();
      }}
      dismissible
    >
      {message}
    </Alert>
  ) : null;
}
