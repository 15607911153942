import React from "react";
import { ElementProfile } from "./ElementProfile";

const traceMinerals = [
  {
    name: "Iron (Fe)",
    reportKey: "fe_ppm",
    code: "fe",
  },
  {
    name: "Manganese (Mn)",
    reportKey: "mn_ppm",
    code: "mn",
  },
  {
    name: "Zinc (Zn)",
    reportKey: "zn_ppm",
    code: "zn",
  },
  {
    name: "Copper (Cu)",
    reportKey: "cu_ppm",
    code: "cu",
  },
  {
    name: "Molybdenum (Mo)",
    reportKey: "mo_ppm",
    code: "mo",
  },
  {
    name: "Cobalt (Co)",
    reportKey: "co_ppm",
    code: "co",
  },
  {
    name: "Selenium (Se)",
    reportKey: "se_ppm",
    code: "se",
  },
  {
    name: "Iodine (I)",
    reportKey: "i_ppm",
    code: "i",
  },
];

export const TraceMinerals = ({ report, samplingEventType, edit = false }) => {
  return traceMinerals.map((item) => (
    <ElementProfile
      key={item.reportKey}
      item={item}
      category="trace"
      report={report}
      unit="%DM"
      samplingEventType={samplingEventType}
      edit={edit}
    />
  ));
};
