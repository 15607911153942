import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Prompt, useRouteMatch, useHistory } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider } from "react-hook-form";
import Button from "react-bootstrap/Button";
import { StickyBarBottom } from "components/StickyBarBottom";

export const FormBase = ({
  isEditMode = false,
  submitButtonLabel = "Add",
  onSubmit,
  isSubmitError = false,
  noButtons = false,
  children,
}) => {
  const formMethods = useForm();
  formMethods.isEditMode = isEditMode;
  const { url } = useRouteMatch();
  const history = useHistory();
  const formRef = useRef(null);
  const [shouldPrompt, setShouldPrompt] = useState(true);

  const [isRefreshing, setIsRefreshing] = useState(false);
  useEffect(() => {
    if (!isEditMode && formMethods.formState.isDirty) {
      // Rerender the form with new default values
      setIsRefreshing(true);
    }
    if (isEditMode) {
      setShouldPrompt(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);
  useEffect(() => {
    if (isRefreshing) {
      setTimeout(() => {
        setIsRefreshing(false);
      }, 100);
    }
  }, [isRefreshing]);

  const submit = (data) => {
    setShouldPrompt(false);
    onSubmit(data);
  };

  const onCancel = () => {
    history.goBack();
  };

  // By rendering "Refreshing...", it forces unmounting/remounting FormProvider,
  // forcing re-rendering with the new defaultValues
  return isRefreshing ? (
    <div>Refreshing...</div>
  ) : (
    <FormProvider {...formMethods}>
      <Form
        noValidate
        onSubmit={formMethods.handleSubmit(submit)}
        ref={formRef}
      >
        {children}

        {!isEditMode && (
          <StickyBarBottom>
            <Button onClick={() => history.push(`${url}/edit`)}>Edit</Button>
          </StickyBarBottom>
        )}

        {!noButtons && isEditMode && (
          <>
            <StickyBarBottom>
              <Button type="submit" className="mr-2">
                {submitButtonLabel}
              </Button>
              <Button type="button" variant="outline-danger" onClick={onCancel}>
                Cancel
              </Button>
            </StickyBarBottom>
            <Prompt
              when={shouldPrompt || isSubmitError}
              message="Are you sure you want to discard changes and leave?"
            />
          </>
        )}
      </Form>
    </FormProvider>
  );
};

FormBase.propTypes = {
  isEditMode: PropTypes.bool,
  submitButtonLabel: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isSubmitError: PropTypes.bool,
  noButtons: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
