import React from "react";
import { useParams } from "react-router-dom";
import { QuestionnaireDairyForm } from "components/forms/QuestionnaireDairyForm";
import { QuestionnaireBeefForm } from "components/forms/QuestionnaireBeefForm";
import { QuestionnaireSheepForm } from "components/forms/QuestionnaireSheepForm";
import { useSamplingEvent } from "components/api/useSamplingEvent";

export const QuestionnairePage = ({ isEditMode }) => {
  const { samplingEventId } = useParams(); // GET sampling event data with this id
  const { isLoading, samplingEvent, farm, title } = useSamplingEvent(
    samplingEventId
  );

  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <React.Fragment>
      <h1>Questionnaire</h1>
      <h2>{title}</h2>

      {samplingEvent.dairy && (
        <QuestionnaireDairyForm
          farmId={farm.id}
          samplingEventId={samplingEventId}
          isEditMode={isEditMode}
        />
      )}
      {samplingEvent.beef && (
        <QuestionnaireBeefForm
          farmId={farm.id}
          samplingEventId={samplingEventId}
          isEditMode={isEditMode}
        />
      )}
      {samplingEvent.sheep && (
        <QuestionnaireSheepForm
          farmId={farm.id}
          samplingEventId={samplingEventId}
          isEditMode={isEditMode}
        />
      )}
    </React.Fragment>
  );
};
