import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { useFormContext } from "react-hook-form";
import { useGet } from "components/api/useGet";
import { DateField } from "components/forms/DateField";

const RequiredStar = () => <span style={{ color: "red" }}>&nbsp;*</span>;

// Translates Django schema in OPTIONS request to form field
// Pass register function and errors object from react-hook-form
export const FormField = ({
  fieldName,
  schema,
  defaultValue,
  errors,
  hideLabel,
  ...rest
}) => {
  const [options, setOptions] = useState(
    Array.isArray(schema.choices) ? schema.choices : []
  );
  useEffect(() => {
    if (Array.isArray(schema.choices)) {
      setOptions(schema.choices);
    }
  }, [schema]);

  // When "choices" is set to url
  // Conditional fetching https://swr.vercel.app/docs/conditional-fetching
  const { data } = useGet(
    typeof schema.choices === "string" ? schema.choices : null
  );
  useEffect(() => {
    if (Array.isArray(data?.results)) {
      setOptions(data.results);
    }
  }, [data]);

  // Don't get errors from here, this will give you an object containing errors
  // for the whole form, whereas the passed errors is narrowed down to the field.
  const { register } = useFormContext();
  const registerOptions = {
    required: {
      value: schema.required,
      message: "This field is required",
    },
  };

  let formControl;
  switch (schema.type) {
    case "string":
    case "email":
      let type = "";
      if (schema.type === "string") {
        type = schema.max_length > 0 ? "text" : "textarea";
      } else {
        type = schema.type;
      }
      if (type === "email") {
        registerOptions.pattern = {
          // https://emailregex.com/
          // eslint-disable-next-line no-useless-escape
          value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: "Invalid email address",
        };
      }
      if (type === "text" || type === "email") {
        formControl = (
          <Form.Control
            type={type}
            name={fieldName}
            ref={register(registerOptions)}
            maxLength={schema.max_length}
            readOnly={schema.read_only}
            isInvalid={!!errors}
            defaultValue={defaultValue}
            {...rest}
          />
        );
      } else if (type === "textarea") {
        formControl = (
          <Form.Control
            as="textarea"
            rows={3}
            name={fieldName}
            ref={register(registerOptions)}
            maxLength={schema.max_length}
            isInvalid={!!errors}
            defaultValue={defaultValue}
            {...rest}
          />
        );
      }
      break;

    case "date":
      formControl = (
        <DateField
          schema={schema}
          name={fieldName}
          errors={errors}
          defaultValue={defaultValue}
          {...rest}
        />
      );
      break;

    case "integer":
    case "float":
    case "decimal":
      registerOptions.valueAsNumber = true;
      let step = null;
      if (schema.type === "integer") {
        step = 1;
      } else if (schema.type === "decimal" && schema.decimal_places) {
        step = 1 / 10 ** schema.decimal_places;
      }
      formControl = (
        <Form.Control
          type="number"
          name={fieldName}
          ref={register(registerOptions)}
          min={schema.min_value}
          max={schema.max_value}
          step={step}
          readOnly={schema.read_only}
          isInvalid={!!errors}
          defaultValue={defaultValue}
          {...rest}
        />
      );
      break;

    case "boolean":
      formControl = (
        <>
          <Form.Check
            type="radio"
            name={fieldName}
            label="Yes"
            value={true}
            id={`${fieldName}-yes`}
            ref={register(registerOptions)}
            defaultChecked={defaultValue}
            {...rest}
          />
          <Form.Check
            type="radio"
            name={fieldName}
            label="No"
            value={false}
            id={`${fieldName}-no`}
            ref={register(registerOptions)}
            defaultChecked={!defaultValue}
            {...rest}
          />
        </>
      );
      break;

    case "multiple_choice":
      formControl =
        options.length < 1 ? (
          <div>Loading...</div>
        ) : (
          <>
            {options.map((option) => (
              <Form.Check
                key={option.id}
                type="checkbox"
                name={fieldName}
                label={option.name}
                value={option.id}
                id={`${fieldName}-${option.id}`}
                ref={register(registerOptions)}
                defaultChecked={
                  defaultValue && option.id && defaultValue.includes(option.id)
                }
                isInvalid={!!errors}
                {...rest}
              />
            ))}
          </>
        );
      break;

    case "choice":
      // Convert empty value to null.
      // This is specifically needed for lameness choices where "" is not allowed.
      registerOptions.setValueAs = (value) => {
        if (value === "") {
          return null;
        }
        return value;
      };
      formControl =
        options.length < 1 ? (
          <div>Loading...</div>
        ) : (
          <>
            <Form.Control
              as="select"
              name={fieldName}
              ref={!rest.readOnly ? register(registerOptions) : null}
              defaultValue={defaultValue}
              isInvalid={!!errors}
              disabled={rest.readOnly}
              {...rest}
            >
              <option value="">-- Please select --</option>
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.display_name}
                </option>
              ))}
            </Form.Control>
            {rest.readOnly && (
              <Form.Control
                type="text"
                name={fieldName}
                ref={register(registerOptions)}
                defaultValue={defaultValue}
                hidden
              />
            )}
          </>
        );
      break;

    case "plaintext":
      formControl = <div className="plaintext-field">{defaultValue}</div>;
      break;

    default:
      break;
  }

  // No layout elements for hidden field
  if (rest.hidden) {
    return formControl;
  }

  return formControl ? (
    <Form.Group controlId={fieldName}>
      <Form.Label className={hideLabel ? "sr-only" : ""}>
        {schema.label}
        {schema.required && <RequiredStar />}
      </Form.Label>
      {formControl}
      {errors && (
        <Form.Control.Feedback type="invalid">
          {errors.message}
        </Form.Control.Feedback>
      )}
      {schema.help_text && (
        <Form.Text className="text-muted">{schema.help_text}</Form.Text>
      )}
    </Form.Group>
  ) : (
    <div>Failed to render "{fieldName}" field.</div>
  );
};

FormField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  schema: PropTypes.object.isRequired,
  defaultValue: PropTypes.any,
  errors: PropTypes.object,
  hideLabel: PropTypes.bool,
};
