import React from "react";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useFarmTypes } from "components/api/useFarmTypes";

export const TypeSelect = ({ farmId, selected, setType }) => {
  const { isLoading, dairy, beef, sheep } = useFarmTypes(farmId);

  return isLoading ? (
    <div>Loading...</div>
  ) : !dairy && !beef && !sheep ? (
    <div>
      Farm type is not set. Please add one or more types in{" "}
      <Link to={`/farms/${farmId}`}>farm page</Link> to continue.
    </div>
  ) : (
    <Form.Group controlId="select-sample-type">
      <Form.Label>Select type of sampling event</Form.Label>
      <Form.Control
        as="select"
        value={selected}
        onChange={(e) => setType(e.target.value)}
      >
        <option value="">-- Please select --</option>
        {dairy && <option value="dairy">Dairy Cow</option>}
        {beef && <option value="beef">DryStock Beef</option>}
        {sheep && <option value="sheep">DryStock Sheep</option>}
      </Form.Control>
    </Form.Group>
  );
};
