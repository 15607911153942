import React from "react";
import { Link, useParams } from "react-router-dom";
import { useSamplingEvent } from "components/api/useSamplingEvent";
import { SamplingEventStatusBadge } from "components/SamplingEventStatusBadge";
import { StatusToggleButton } from "./StatusToggleButton";
import { QuestionnaireSection } from "./QuestionnaireSection";
import { LabAnalysisSection } from "./LabAnalysisSection";
import { DietaryAnalysisSection } from "./DietaryAnalysisSection";
import { DietaryCommentarySection } from "./DietaryCommentarySection";
import { ActionSummarySection } from "./ActionSummarySection";
import { sortFileCreatedDesc } from "services";
import "./index.scss";

export const SamplingEventPage = () => {
  const { samplingEventId } = useParams(); // GET sampling event data with this id
  const { isLoading, error, samplingEvent, title, mutate } = useSamplingEvent(
    samplingEventId
  );

  // TODO: should be when both PDFs exist
  const reportsReady =
    samplingEvent?.diet_commentary_report.length > 0 &&
    samplingEvent?.action_summary_report.length > 0;

  const latestDietCommentary =
    reportsReady &&
    samplingEvent.diet_commentary_report.sort(sortFileCreatedDesc)[0];
  const latestActionSummary =
    reportsReady &&
    samplingEvent.action_summary_report.sort(sortFileCreatedDesc)[0];

  return error ? (
    <div>Something went wrong...</div>
  ) : isLoading ? (
    <div>Loading...</div>
  ) : (
    <React.Fragment>
      <div className="d-flex align-items-start justify-content-between">
        <h1>{title}</h1>
        <StatusToggleButton samplingEvent={samplingEvent} mutate={mutate} />
      </div>

      <div className="d-flex align-items-baseline mb-3">
        <SamplingEventStatusBadge status={samplingEvent.status} />
        <div className="text-muted">
          #{samplingEvent.id} created by {samplingEvent.consultant}
        </div>
      </div>

      {reportsReady && (
        <div className="mb-4 border-top border-bottom pt-1 pb-1">
          <div className="d-flex flex-wrap">
            <div className="report-access-label">View online report:</div>
            <div>
              <Link to={`/sampling-events/${samplingEventId}/commentary`}>
                Dietary Commentary Report
              </Link>
              <span className="text-muted ml-2 mr-2">|</span>
              <Link to={`/sampling-events/${samplingEventId}/action`}>
                Action Summary Report
              </Link>
            </div>
          </div>

          <div className="d-flex flex-wrap">
            <div className="report-access-label">View latest PDF:</div>
            <div>
              <a href={latestDietCommentary.file}>Dietary Commentary Report</a>
              <span className="text-muted ml-2 mr-2">|</span>
              <a href={latestActionSummary.file}>Action Summary Report</a>
            </div>
          </div>
        </div>
      )}

      <QuestionnaireSection samplingEvent={samplingEvent} />
      <LabAnalysisSection samplingEvent={samplingEvent} mutate={mutate} />
      <DietaryAnalysisSection samplingEvent={samplingEvent} />
      <DietaryCommentarySection samplingEvent={samplingEvent} mutate={mutate} />
      <ActionSummarySection samplingEvent={samplingEvent} mutate={mutate} />
    </React.Fragment>
  );
};
