// https://reactrouter.com/web/example/auth-workflow
// https://usehooks.com/useAuth/
import React, { useState, useContext, createContext, useEffect } from "react";
import { api } from "components/api/api";
import Cookies from "js-cookie";
import { useAlert } from "components/alert/useAlert";

// Export for testing
export const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Axios instance is a singleton. Set csrftoken here and the following
// requests don't need to take care of it.
const setCsrftoken = () => {
  api.defaults.headers.common["X-CSRFToken"] = Cookies.get("csrftoken");
};

// Export for testing
export function useProvideAuth() {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const alert = useAlert();

  const login = async ({ email, password }) => {
    alert.clear();
    try {
      const res = await api.post("/session", { email, password });
      if (res.data.email) {
        setSession(res.data);
        setCsrftoken();
      } else {
        throw Error("Email is missing in the response.");
      }
    } catch (error) {
      console.error(error)
      if (error.response) {
        alert.set({
          message: error.response.data.toString(),
          variant: "danger",
        });
      } else {
        alert.set({
          message: "Something went wrong. Please try again later.",
          variant: "danger",
        });
      }
    }
  };

  const logout = async () => {
    try {
      await api.delete("/session");
    } catch (error) {
      if (error.response) {
        // When response status code is out of 2xx range
        console.error(error.response);
      } else if (error.request) {
        // When no response was received after request was made
        console.error(error.request);
      } else {
        // Error
        console.error(error.message);
      }
    }

    setSession(null);
  };

  // Check user's login status on load.
  useEffect(() => {
    if (!session) {
      const getUser = async () => {
        setLoading(true);
        try {
          const res = await api.get("/session");
          if (res.data.email) {
            setSession(res.data);
            setCsrftoken();
          } else {
            // Should be something wrong with the user's login status.
            throw Error("Email is missing in the response.");
          }
        } catch (error) {
          // Error could be 404 (not logged in) or other errors.
          // In any case, treat as not logged in anymore.
          setSession(null);
        }
        setLoading(false);
      };

      getUser();
    }
  }, [session]);

  return {
    session,
    loading,
    login,
    logout,
  };
}
