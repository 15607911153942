import React from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import { StockClassDisplayName } from "components/StockClassDisplayName";
import { PrescriptionChoices } from "./PrescriptionChoices";
import { useSamplingEvent } from "components/api/useSamplingEvent";
import { getStockCountUnit } from "services/samplingEvent";

export const MacroMineralSupplementationTable = ({
  stockClasses,
  samplingEventId,
  report,
  isEdit = false,
}) => {
  // Sampling event type needed to get stock class display name
  const {
    isLoading,
    type: samplingEventType,
    error,
  } = useSamplingEvent(samplingEventId);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Failed to load table data.</div>;
  }
  return (
    <Table bordered size="sm" className="macro-mineral-supplementation-table">
      <thead>
        <tr>
          <th rowSpan="2">Element</th>
          <th rowSpan="2">Product</th>
          <th colSpan={stockClasses.length}>
            Product Prescription Rate (g/{getStockCountUnit(samplingEventType)}
            /day)
          </th>
        </tr>
        <tr>
          {stockClasses.map((stockClass) => (
            <th key={stockClass} className={stockClass}>
              <StockClassDisplayName
                stockClass={stockClass}
                samplingEventType={samplingEventType}
              />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {report?.macro_product_table?.map((product) => (
          <tr key={product.name}>
            <td>{product.element}</td>
            <td>{product.name}</td>
            {stockClasses.map((stockClass) => {
              const isPrescribed = !!product.base_product_amounts[stockClass];

              return (
                <td key={stockClass} className={stockClass}>
                  {!isPrescribed && <div className="text-muted">Nil</div>}
                  {isPrescribed && (
                    <PrescriptionChoices
                      product={product}
                      stockClass={stockClass}
                      animalType={report.animal_type}
                      prescription={`${product.base_product_amounts[stockClass].amount} ${product.base_product_amounts[stockClass].unit}`}
                      isEdit={isEdit}
                    />
                  )}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

MacroMineralSupplementationTable.propTypes = {
  stockClasses: PropTypes.array.isRequired,
  samplingEventId: PropTypes.string.isRequired,
  report: PropTypes.object.isRequired,
  isEdit: PropTypes.bool,
};
