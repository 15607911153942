import React from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";

export const MacroElements = ({ reportData, stockCountUnit }) => {
  const macro = [
    reportData.ca_percent,
    reportData.cl_percent,
    reportData.mg_percent,
    reportData.p_percent,
    reportData.k_percent,
    reportData.na_percent,
    reportData.s_percent,
  ];
  return (
    <>
      <h2>Macro Elements</h2>
      <Table hover size="sm" responsive className="element-analysis-table">
        <thead>
          <tr>
            <th></th>
            <th>Aggregate %</th>
            <th>Total Ingested (g/{stockCountUnit}/d)</th>
            <th>CoA</th>
            <th>Absorbed Units (g/{stockCountUnit}/d)</th>
            <th>Required Levels (g/{stockCountUnit}/d)</th>
            <th>Status</th>
            <th>Element Required (g/{stockCountUnit}/d)</th>
            <th>%Suff/Def</th>
          </tr>
        </thead>
        <tbody>
          {macro.map((element) => {
            return (
              element && (
                <tr key={element.label}>
                  <th>{element.label}</th>
                  <td>{element.aggregate_value.toFixed(2)}</td>
                  <td>{element.total_ingested.toFixed(2)}</td>
                  <td>{element.coa.toFixed(2)}</td>
                  <td>{element.absorbed_units.toFixed(2)}</td>
                  <td>{element.required_levels.toFixed(2)}</td>
                  <td>{element.status.toFixed(2)}</td>
                  <td>
                    {element.element_required?.supplement_value?.toFixed(2)}
                  </td>
                  <td>{element.percent_suffdeff?.toFixed(2) || "Err"} %</td>
                </tr>
              )
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

MacroElements.propTypes = {
  reportData: PropTypes.object.isRequired,
};
