import React from "react";
import { useStockClassDisplayName } from "components/api/useStockClassDisplayName";

export const StockClassDisplayName = ({ stockClass, samplingEventType }) => {
  const stckClassDisplayName = useStockClassDisplayName(
    samplingEventType,
    stockClass
  );
  return <>{stckClassDisplayName}</>;
};
