import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { FormFieldWrapper } from "./FormFieldWrapper";

export const NestedObject = ({
  fieldName,
  fieldSchema,
  uiSchema,
  defaultValue,
  fieldId,
  errors,
  ...rest
}) => {
  if (!Array.isArray(uiSchema.items) || uiSchema.items.length < 1) {
    return <div>uiSchema missing for child items</div>;
  }
  const OuterWrapper = uiSchema?.ui?.inline ? Form.Row : React.Fragment;
  return (
    <OuterWrapper>
      {uiSchema.items.map((subUiSchema) => {
        let subFieldName = "";
        if (typeof subUiSchema === "string") {
          subFieldName = subUiSchema;
        } else {
          subFieldName = subUiSchema.name;
        }
        const subFieldErrors = errors?.[subFieldName];
        // If ID is specified, backend will update based on ID.
        // If not, a new record will be added.
        if (
          subFieldName === "id" &&
          (!defaultValue || !defaultValue[subFieldName])
        ) {
          // This needs to be null. Otherwise react-hook-form adds empty strings
          // for its value and backend fails to add a new record.
          return null;
        }
        // Remove wrapper from hidden field to prevent layout issues
        const hidden = subUiSchema?.ui?.hidden === true;
        const InnerWrapper =
          uiSchema?.ui?.inline && !hidden ? Col : React.Fragment;
        const innerWrapperProps =
          uiSchema?.ui?.inline && !hidden ? { xs: 12, md: true } : {};
        return (
          <InnerWrapper
            key={`${fieldId || fieldName}.${subFieldName}`}
            {...innerWrapperProps}
          >
            <FormFieldWrapper
              fieldName={`${fieldName}.${subFieldName}`}
              fieldSchema={fieldSchema.children[subFieldName]}
              uiSchema={subUiSchema}
              defaultValue={defaultValue?.[subFieldName]}
              errors={subFieldErrors}
              {...rest}
            />
          </InnerWrapper>
        );
      })}
    </OuterWrapper>
  );
};
