// Takes date string (yyyy-mm-dd) and returns season
// 01/07 - 31/08 : Late Winter / Early Spring
// 01/09 - 20/11 : Spring
// 21/11 - 20/01 : Summer
// 21/01 - 31/03 : Late Summer / Early Autumn
// 01/04 - 20/05 : Autumn
// 21/05 - 30/06 : Winter
export const getSeason = (dateString) => {
  const date = new Date(dateString);
  if (date.toString() === "Invalid Date") {
    console.error(`Wrong date '${dateString}' was passed to getSeason`);
    return "";
  }

  const year = date.getFullYear();

  // new Date(year, monthIndex, date)
  // monthIndex is actual month - 1
  const lateSummerFrom = new Date(year, 0, 21);
  const autumnFrom = new Date(year, 3, 1);
  const winterFrom = new Date(year, 4, 21);
  const lateWinterFrom = new Date(year, 6, 1);
  const springFrom = new Date(year, 8, 1);
  const summerFrom = new Date(year, 10, 21);

  if (lateSummerFrom <= date && date < autumnFrom) {
    return "Late Summer / Early Autumn";
  }
  if (autumnFrom <= date && date < winterFrom) {
    return "Autumn";
  }
  if (winterFrom <= date && date < lateWinterFrom) {
    return "Winter";
  }
  if (lateWinterFrom <= date && date < springFrom) {
    return "Late Winter / Early Spring";
  }
  if (springFrom <= date && date < summerFrom) {
    return "Spring";
  }
  // The rest is Summer, either the beginning or the end of the year
  return "Summer";
};

export const getTodayString = () => {
  const today = new Date();
  return getInputDateString(today);
};

export const getInputDateString = (date) => {
  if (!date) {
    return null;
  }
  if (date.toString() === "Invalid Date") {
    console.error(`Wrong date was passed to getDisplayDate:`, date);
    return null;
  }
  return `${date.getFullYear()}-${date.getMonth() < 9 ? "0" : ""}${
    date.getMonth() + 1
  }-${date.getDate() < 10 ? "0" : ""}${date.getDate()}`;
};

export const handleApiError = ({ apiState, alert }) => {
  const status = apiState.data?.status;
  const statusText = apiState.data?.statusText;
  if (status === 400) {
    // Bad Request
    const errorData = JSON.stringify(apiState.data?.data);
    alert.set({
      message: (
        <>
          <div>Please check the the following errors:</div>
          <div>{errorData}</div>
        </>
      ),
      variant: "danger",
    });
  } else if (status >= 500) {
    // Server error
    alert.set({
      message: (
        <>
          <div>
            Server is experiencing issue: {status} {statusText}
          </div>
        </>
      ),
      variant: "danger",
    });
  } else {
    const errorMessage = apiState.data?.data?.error;
    // Other errors
    alert.set({
      message: (
        <>
          <div>
            Something went wrong: {status} {statusText}
          </div>
          {errorMessage && <div>{errorMessage}</div>}
        </>
      ),
      variant: "danger",
    });
  }
};

export const capitalise = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const getDisplayDate = (dateString) => {
  const date = new Date(dateString);
  if (date.toString() === "Invalid Date") {
    console.error(`Wrong date '${dateString}' was passed to getDisplayDate`);
    return "";
  }

  return generateDisplayDate(date);
};

export const getDisplayDateTime = (dateString) => {
  const date = new Date(dateString);
  if (date.toString() === "Invalid Date") {
    console.error(
      `Wrong date '${dateString}' was passed to getDisplayDateTime`
    );
    return "";
  }

  return `${generateDisplayDate(date)} ${generateDisplayTime(date)}`;
};

const generateDisplayDate = (date) =>
  `${date.getDate() < 10 ? "0" : ""}${date.getDate()}/${
    date.getMonth() < 9 ? "0" : ""
  }${date.getMonth() + 1}/${date.getFullYear()}`;

const generateDisplayTime = (date) =>
  `${date.getHours() < 10 ? "0" : ""}${date.getHours()}:${
    date.getMinutes() < 10 ? "0" : ""
  }${date.getMinutes()}`;

export const getSamplingEventTitle = (samplingEvent, farm) => {
  if (!samplingEvent || !farm) {
    return "";
  }
  const date = getDisplayDate(samplingEvent.date);
  const type = samplingEvent.dairy
    ? "Dairy Cow"
    : samplingEvent.beef
    ? "Drystock Beef"
    : samplingEvent.sheep
    ? "Drystock Sheep"
    : "";
  return `${date} - ${farm.name} - ${type}`;
};

// Theme override function for react-select
export const reactSelectTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#557f49",
    primary75: "#7cbc62",
    primary50: "#89c273",
    primary25: "#9dca7f",
    danger: "#dc3545",
  },
});

export const sortFileCreatedDesc = (a, b) => {
  const createdA = new Date(a.created);
  const createdB = new Date(b.created);
  return createdB - createdA;
};

export const getSamplingEventType = (samplingEvent) =>
  samplingEvent?.dairy
    ? "dairy"
    : samplingEvent?.beef
    ? "beef"
    : samplingEvent?.sheep
    ? "sheep"
    : undefined;
