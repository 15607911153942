import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FormFieldWrapper } from "./FormFieldWrapper";
import "./FieldArray.scss";

// Build default value object to satisfy the library requirement.
// Actual value is not needed for now.
const buildDefaultValueOnAppend = (fieldSchema) => {
  let defaultValue = {};
  if (fieldSchema.type === "nested object") {
    Object.keys(fieldSchema.children).forEach((subFieldName) => {
      if (subFieldName !== "id") {
        defaultValue[subFieldName] = undefined;
      }
    });
  } else {
    defaultValue.value = undefined;
  }
  return defaultValue;
};

export const FieldArray = ({
  fieldName,
  fieldSchema,
  uiSchema,
  defaultValue,
  errors,
  minLength = 1,
  ...rest
}) => {
  const [initialised, setInitialised] = useState(false);
  const { fields, append, remove } = useFieldArray({
    // control is omitted because using FormContext
    name: fieldName,
    keyName: "arrayId",
  });
  const defaultValueOnAppend = buildDefaultValueOnAppend(fieldSchema);

  // ui.array_unique can be set to choices field
  // if set, remove already selected values from the options
  const { watch } = useFormContext();
  let selected = [];
  let isFullLength = false;
  if (uiSchema?.ui?.array_unique && fields.length) {
    // Only watch array_unique fields and not other fields in the array.
    // This is a huge performance difference.
    let fieldsToWatch = [];
    fieldsToWatch = fields.map(
      (field, i) => `${fieldName}[${i}].${uiSchema.ui.array_unique}`
    );
    const watchUnique = watch(fieldsToWatch, fields);
    selected = fieldsToWatch.map((fieldName) => watchUnique[fieldName]);
    isFullLength =
      selected.length >=
      fieldSchema.children?.[uiSchema.ui.array_unique]?.choices?.length;
  }

  // beef.finishing.growth_rates to show stock classes selected in
  // beef.finishing.cattle
  let growthRatesStockClasses = [];
  if (fieldName === "beef.finishing.growth_rates") {
    const watchStockClasses = watch("beef.finishing.cattle", fields);
    growthRatesStockClasses = watchStockClasses.map(
      (field) => field.stock_class
    );
  }

  useEffect(() => {
    // append needs to be called one per render
    // This should only run on setup, or remove doesn't work
    if (initialised) {
      return;
    }
    const defaultLength = uiSchema?.ui?.array_length || 1;
    if (defaultValue?.length) {
      if (fields.length < defaultValue.length) {
        const index = fields.length;
        append(defaultValue[index], false);
      } else if (fields.length < defaultLength) {
        append(defaultValueOnAppend, false);
      } else {
        setInitialised(true);
      }
    } else {
      if (fields.length < defaultLength) {
        append(defaultValueOnAppend, false);
      } else {
        setInitialised(true);
      }
    }
  }, [
    initialised,
    fields,
    append,
    defaultValue,
    defaultValueOnAppend,
    uiSchema,
  ]);

  // Remove `array: true` from uiSchema to avoid infinite loop
  const childUiSchema = {
    ...uiSchema,
    ui: {
      ...uiSchema.ui,
      array: false,
    },
  };

  return (
    <div className="field-array">
      {fields.map((item, index) => {
        let arrayFieldName = `${fieldName}[${index}]`;
        let value = item;

        // Update values based on defaultValue
        // This is to update plaintext values that can't get updated otherwise
        // react-hook-form might not like this since it's not using field id
        // It should be okay as long as defaultValue is in sync
        let passedValue = defaultValue?.[index];
        if (passedValue) {
          value = { ...value, ...passedValue };
        }

        if (fieldSchema.type !== "nested object") {
          arrayFieldName += ".value";
          value = item.value;
        }

        const arrayFieldErrors = errors?.[index];

        // beef.finishing.growth_rates to show stock classes selected in
        // beef.finishing.cattle
        let hideRow = false;
        if (fieldName === "beef.finishing.growth_rates") {
          if (!growthRatesStockClasses.includes(value.stock_class)) {
            hideRow = true;
          }
        }

        // Update choices for array_unique fields:
        // Remove selected values from choices.
        let fieldSchemaModified = null;
        if (uiSchema?.ui?.array_unique) {
          const thisSelected = selected[index];
          const choices = fieldSchema.children?.[
            uiSchema.ui.array_unique
          ].choices.filter(
            (choice) =>
              choice.value === thisSelected || !selected.includes(choice.value)
          );
          fieldSchemaModified = JSON.parse(JSON.stringify(fieldSchema));
          fieldSchemaModified.children[
            uiSchema.ui.array_unique
          ].choices = choices;
        }

        return (
          <Form.Row
            key={item.arrayId}
            className={`item-row align-items-end ${hideRow && "d-none"}`}
          >
            <Col xs={12} className="d-md-none">
              <strong>
                {fieldSchema.label} {index + 1}
              </strong>
            </Col>
            <Col xs={uiSchema?.ui?.array_length_fixed ? 12 : 10}>
              <FormFieldWrapper
                fieldName={arrayFieldName}
                fieldId={item.arrayId}
                fieldSchema={fieldSchemaModified || fieldSchema}
                uiSchema={childUiSchema}
                defaultValue={value}
                errors={arrayFieldErrors}
                {...rest}
              />
            </Col>
            {!uiSchema?.ui?.array_length_fixed && (
              <Col xs={2}>
                {index > minLength - 1 && (
                  <Button
                    type="button"
                    variant="outline-danger"
                    onClick={() => remove(index)}
                  >
                    Delete
                  </Button>
                )}
              </Col>
            )}
          </Form.Row>
        );
      })}
      {!uiSchema?.ui?.array_length_fixed && !isFullLength && (
        <Form.Row>
          <Col xs={{ span: 2, offset: 10 }}>
            <Button
              type="button"
              variant="outline-primary"
              onClick={() => append(defaultValueOnAppend, false)}
            >
              Add {fieldSchema.label}
            </Button>
          </Col>
        </Form.Row>
      )}
    </div>
  );
};
