import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useStockClassSchema } from "components/api/useStockClassSchema";
import { getSamplingEventType } from "services";
import { Section } from "./Section";
import { AddDietaryAnalysisForm } from "./AddDietaryAnalysisForm";
import "./DietaryAnalysisSection.scss";

const getSelectedStockClasses = (samplingEvent) => {
  let selected = [];
  const mapStockClass = (row) => row.stock_class;
  if (samplingEvent.dairy) {
    selected = samplingEvent.dairy.dietary?.map(mapStockClass);
  } else if (samplingEvent.beef) {
    const breedings = samplingEvent.beef.breeding?.stock?.map(mapStockClass);
    const finishings = samplingEvent.beef.finishing?.cattle?.map(mapStockClass);
    selected = breedings.concat(finishings);
  } else if (samplingEvent.sheep) {
    selected = samplingEvent.sheep.feeding?.map(mapStockClass);
  }
  const unique = (value, index, self) => {
    return self.indexOf(value) === index;
  };
  return selected.filter(unique);
};

export const DietaryAnalysisSection = ({ samplingEvent }) => {
  // Get the schema of stock classes for the sampling event type
  const type = getSamplingEventType(samplingEvent);
  const stockClassSchema = useStockClassSchema()?.[type] || [];

  // Get a unique list of stock classes selected in the questionnaire
  // This is the possible stock classes in this sampling event
  const selectedStockClasses = getSelectedStockClasses(samplingEvent);

  // Get stock classes of existing analyses for this sampling event
  const analysesStockClasses = []
    .concat(
      samplingEvent.dairy_dietary_analysis,
      samplingEvent.youngstock_dietary_analysis,
      samplingEvent.replacement_stock_dietary_analysis,
      samplingEvent.sheep_dietary_analysis,
      samplingEvent.lamb_dietary_analysis
    )
    .map((analysis) => analysis.stock_class);

  let options = [];
  if (stockClassSchema.length > 0) {
    options = selectedStockClasses
      .filter((stockClass) => !analysesStockClasses.includes(stockClass))
      .map((stockClass) =>
        stockClassSchema?.find((schema) => schema.value === stockClass)
      );
  }

  return (
    <Section isDone={true}>
      <h2>Dietary Analysis Report</h2>
      <p>
        Please create Dietary Analysis Report of all the stock classes to be
        included in the reports. <br />
        Only the stock classes recorded in the questionnaire can be included in
        the analyses.
      </p>

      <div className="mb-3">
        {analysesStockClasses.length > 0 &&
          stockClassSchema.length > 0 &&
          analysesStockClasses.map((analysisStockClass) => (
            <div
              key={analysisStockClass}
              className="d-flex align-items-baseline"
            >
              <Link
                to={`/sampling-events/${samplingEvent.id}/analysis/${analysisStockClass}`}
                className="dietary-analysis-link"
              >
                {
                  stockClassSchema.find(
                    (stockClass) => stockClass.value === analysisStockClass
                  ).display_name
                }
              </Link>
            </div>
          ))}
      </div>

      {options.length > 0 ? (
        <AddDietaryAnalysisForm
          samplingEvent={samplingEvent}
          options={options}
        />
      ) : (
        <small className="text-muted">All stock classes are added.</small>
      )}
    </Section>
  );
};

DietaryAnalysisSection.propTypes = {
  samplingEvent: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};
