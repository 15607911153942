import React, { useState } from "react";
import { ReportDataTable } from "./ReportDataTable";
import { RitchTextArea } from "components/forms/RitchTextArea";
import { RitchTextRenderer } from "components/RitchTextRenderer";

export const ElementProfile = ({
  item,
  report,
  category,
  unit,
  samplingEventType,
  edit = false,
}) => {
  const commentKey = `${category}_${item.code}_comment`;
  const recommendationKey = `${category}_${item.code}_recommendation`;
  const [comment, setComment] = useState(report[commentKey] || "");
  const [recommendation, setRecommendation] = useState(
    report[recommendationKey] || ""
  );
  let reportDataCategory = category;
  if (category === "macro" || category === "trace") {
    reportDataCategory = category + "_profile";
  }

  return (
    <div className="mt-4">
      <h3>{item.name}</h3>

      <ReportDataTable
        stockClasses={report.report_data.stock_classes}
        reportData={report.report_data?.[reportDataCategory]?.[item.reportKey]}
        unit={unit}
        samplingEventType={samplingEventType}
        edit={edit}
      />

      <h4>Comments</h4>
      {edit ? (
        <RitchTextArea
          name={commentKey}
          value={comment}
          onChange={setComment}
        />
      ) : (
        <RitchTextRenderer html={report[commentKey]} />
      )}

      <h4>Recommendations</h4>
      {edit ? (
        <RitchTextArea
          name={recommendationKey}
          value={recommendation}
          onChange={setRecommendation}
        />
      ) : (
        <RitchTextRenderer html={report[recommendationKey]} />
      )}
    </div>
  );
};
