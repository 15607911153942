import React from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import { StockClassDisplayName } from "components/StockClassDisplayName";
import { PRODUCT_ELEMENT_MAP } from "services/constants";
import { PrescriptionChoices } from "../PrescriptionChoices";

const NonDairyPrescriptionRateCells = ({
  product,
  stockClass,
  animalType,
  percentage,
  isEdit = false,
}) => {
  const prescription = product.base_product_amounts[stockClass];
  const prescriptionRate = (Number(prescription?.amount) * Number(percentage) / 100).toFixed(2);
  // If it's sheep and edit mode, return choice field
  return (
    <td className={stockClass}>
      {!prescription && <div className="text-muted">Nil</div>}
      {prescription && (
        <PrescriptionChoices
          product={product}
          stockClass={stockClass}
          animalType={animalType}
          prescription={`${prescriptionRate} ${prescription.unit}`}
          isEdit={isEdit}
        />
      )}
    </td>
  );
};

const NonDairyProductRow = ({
  product,
  productLookups,
  stockClasses,
  animalType,
  isEdit = false,
}) => {
  const productLookup = productLookups.find(
    (lookup) => lookup.id === product.productid
  );
  const percentageKey = PRODUCT_ELEMENT_MAP.find(
    (map) => map.element === product.element
  )?.key;
  const percentage =
    productLookup && percentageKey && productLookup[percentageKey];

  return (
    <tr>
      <td>{product.element}</td>
      <td>{product.name}</td>
      {!percentage && (
        <td colSpan={stockClasses.length}>
          Mineral product lookup not defined.
        </td>
      )}
      {percentage &&
        stockClasses.map((stockClass) => (
          <NonDairyPrescriptionRateCells
            key={stockClass}
            product={product}
            stockClass={stockClass}
            animalType={animalType}
            percentage={percentage}
            isEdit={isEdit}
          />
        ))}
    </tr>
  );
};

export const NonDairyTraceMineralSupplementationTable = ({
  stockClasses,
  animalType,
  samplingEventType,
  traceProductTable,
  productLookups,
  isEdit = false,
}) => (
  <Table bordered size="sm">
    <thead>
      <tr>
        <th rowSpan="2">Element</th>
        <th rowSpan="2">Suggested Product</th>
        <th colSpan={stockClasses.length}>Elemental Prescription Rate (mg)</th>
      </tr>
      <tr>
        {stockClasses.map((stockClass) => (
          <th key={stockClass} className={stockClass}>
            <StockClassDisplayName
              stockClass={stockClass}
              samplingEventType={samplingEventType}
            />
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {traceProductTable?.map((product) => (
        <NonDairyProductRow
          key={product.productid}
          product={product}
          productLookups={productLookups}
          stockClasses={stockClasses}
          animalType={animalType}
          isEdit={isEdit}
        />
      ))}
    </tbody>
  </Table>
);

NonDairyTraceMineralSupplementationTable.propTypes = {
  animalType: PropTypes.string.isRequired,
  stockClasses: PropTypes.array.isRequired,
  samplingEventType: PropTypes.string.isRequired,
  traceProductTable: PropTypes.array.isRequired,
  productLookups: PropTypes.array.isRequired,
  isEdit: PropTypes.bool,
};
