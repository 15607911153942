import { useSchema } from "components/api/useSchema";

export const useStockClassSchema = () => {
  const { schema } = useSchema("/sampling-event");
  const dairy =
    schema?.dairy?.children?.dietary?.child?.children?.stock_class?.choices;
  const breedings =
    schema?.beef?.children?.breeding?.children?.stock?.child?.children
      ?.stock_class?.choices;
  const finishings =
    schema?.beef?.children?.finishing?.children?.cattle?.child?.children
      ?.stock_class?.choices;
  const sheep =
    schema?.sheep?.children?.feeding?.child?.children?.stock_class?.choices;
  return {
    dairy,
    beef: breedings?.concat(finishings),
    sheep,
  };
};
