import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import BSPagination from "react-bootstrap/Pagination";

export const Pagination = ({ maxPage, currentPage }) => {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  return maxPage > 1 ? (
    <BSPagination>
      {[...Array(maxPage + 1).keys()].slice(1).map((pageNumber) => (
        <BSPagination.Item
          key={pageNumber}
          active={pageNumber === currentPage}
          onClick={() => {
            urlParams.set("page", pageNumber);
            history.push({ search: `?${urlParams.toString()}` });
          }}
        >
          {pageNumber}
        </BSPagination.Item>
      ))}
    </BSPagination>
  ) : null;
};
