import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams } from "react-router-dom";
import { useSamplingEvent } from "components/api/useSamplingEvent";
import { useStockClassDisplayName } from "components/api/useStockClassDisplayName";
import { useGet } from "components/api/useGet";
import { Metadata } from "./Metadata";
import { DietaryData } from "./DietaryData";
import { SensitivityFactor } from "./SensitivityFactor";
import { MacroElements } from "./MacroElements";
import { TraceElements } from "./TraceElements";
import { Prescription } from "./Prescription";
import { DeleteAnalysisButton } from "./DeleteAnalysisButton";
import { getStockCountUnit } from "services/samplingEvent";
import "./index.scss";

const getAnalysis = (samplingEvent, stockClass) => {
  const dairyAnalysis =
    samplingEvent.dairy_dietary_analysis.length > 0 &&
    samplingEvent.dairy_dietary_analysis.find(
      (analysis) => analysis.stock_class === stockClass
    );
  const youngstockAnalysis =
    samplingEvent.youngstock_dietary_analysis.length > 0 &&
    samplingEvent.youngstock_dietary_analysis.find(
      (analysis) => analysis.stock_class === stockClass
    );
  const replacementAnalysis =
    samplingEvent.replacement_stock_dietary_analysis.length > 0 &&
    samplingEvent.replacement_stock_dietary_analysis.find(
      (analysis) => analysis.stock_class === stockClass
    );
  const sheepAnalysis =
    samplingEvent.sheep_dietary_analysis.length > 0 &&
    samplingEvent.sheep_dietary_analysis.find(
      (analysis) => analysis.stock_class === stockClass
    );
  const lambAnalysis =
    samplingEvent.lamb_dietary_analysis.length > 0 &&
    samplingEvent.lamb_dietary_analysis.find(
      (analysis) => analysis.stock_class === stockClass
    );

  return dairyAnalysis
    ? {
        ...dairyAnalysis,
        type: "dairy",
      }
    : youngstockAnalysis
    ? {
        ...youngstockAnalysis,
        type: "youngstock",
      }
    : replacementAnalysis
    ? {
        ...replacementAnalysis,
        type: "replacement",
      }
    : sheepAnalysis
    ? {
        ...sheepAnalysis,
        type: "sheep",
      }
    : lambAnalysis
    ? {
        ...lambAnalysis,
        type: "lamb",
      }
    : null;
};

export const DietaryAnalysisReportPage = () => {
  const { samplingEventId, stockClass } = useParams();
  const { isLoading, samplingEvent, type } = useSamplingEvent(samplingEventId);
  const stokClassDisplayName = useStockClassDisplayName(type, stockClass);
  const {
    data: report,
    isLoading: isLoadingReport,
    error,
  } = useGet(
    `/reports/dietary-analysis-report/${samplingEventId}/${stockClass}`
  );
  const stockCountUnit = getStockCountUnit(type);

  return isLoading || isLoadingReport ? (
    <div>Loading...</div>
  ) : error ? (
    <div>Something went wrong...</div>
  ) : (
    <>
      <h1 className="mb-4">Element Analysis for {stokClassDisplayName}</h1>
      <Row>
        <Col xs={{ span: 12, order: 2 }} sm={{ span: 6, order: 1 }}>
          <DietaryData report={report} />
        </Col>
        <Col xs={{ span: 12, order: 1 }} sm={{ span: 6, order: 2 }}>
          <Metadata samplingEvent={samplingEvent} data={report.metadata} />
        </Col>
      </Row>

      <section className="sensitivity-factor mb-4">
        <SensitivityFactor
          samplingEvent={samplingEvent}
          stockClass={stockClass}
          analysisData={getAnalysis(samplingEvent, stockClass)}
        />
      </section>

      <section className="macro-elements mb-4">
        <MacroElements
          reportData={report.report_data}
          stockCountUnit={stockCountUnit}
        />
      </section>

      <section className="trace-elements mb-4">
        <TraceElements
          reportData={report.report_data}
          stockCountUnit={stockCountUnit}
        />
      </section>

      <section className="prescription mb-4">
        <Prescription
          reportData={report.report_data}
          DMI={report.dm_intake_total}
          analysisData={getAnalysis(samplingEvent, stockClass)}
        />
      </section>

      <DeleteAnalysisButton />
    </>
  );
};
