import React from "react";

export const KeyDietaryScale = () => (
  <div className="d-flex flex-wrap justify-content-end mb-3">
    <div className="mr-2">
      <strong>Key dietary scale:</strong>
    </div>
    <div className="deficient px-2">Deficient</div>
    <div className="insufficient px-2">Insufficient</div>
    <div className="satisfactory px-2">Satisfactory</div>
    <div className="excessive px-2">Excessive</div>
    <div className="toxic px-2">Toxic</div>
  </div>
);

export const KeyDietaryScaleNonmineral = () => (
  <div className="d-flex flex-wrap justify-content-end mb-3">
    <div className="mr-2">
      <strong>Key dietary scale:</strong>
    </div>
    <div className="deficient px-2">Deficient</div>
    <div className="insufficient px-2">Insufficient</div>
    <div className="satisfactory px-2">Satisfactory</div>
    <div className="excessive px-2">Excessive</div>
    <div className="highrisk px-2">High Risk</div>
  </div>
);
