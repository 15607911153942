import React from "react";
import Button from "react-bootstrap/Button";
import {
  useParams,
  useRouteMatch,
  useHistory,
  Switch,
  Route,
} from "react-router-dom";
import { useGet } from "components/api/useGet";
import { FarmBeefForm } from "components/forms/FarmBeefForm";

export const BeefTab = () => {
  const { farmId } = useParams(); // GET farm data with this id
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const { data, isLoading } = useGet(`/beef/farm?farm=${farmId}`);
  const beef = data?.results?.[0];

  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <Switch>
      <Route exact path={path}>
        {!beef ? (
          <>
            <p>Is this a beef farm? Please add details.</p>
            <Button onClick={() => history.push(`${url}/edit`)}>
              Add beef info
            </Button>
          </>
        ) : (
          <FarmBeefForm farmId={farmId} isEditMode={false} />
        )}
      </Route>
      <Route exact path={`${path}/edit`}>
        <FarmBeefForm farmId={farmId} isEditMode />
      </Route>
    </Switch>
  );
};
