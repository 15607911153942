import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "./useAuth";
import { LogoutButton } from "./LogoutButton";

export function LoginPage() {
  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const urlParams = new URLSearchParams(location.search);
  const { from } = location.state || { from: { pathname: "/" } };
  const login = async (e) => {
    e.preventDefault();
    await auth.login({ email, password });
    if (urlParams.get("next")) {
      // One example use case is downloading csv when not logged in.
      // This can't be history.replace because then it will route inside
      // React app, and doesn't make request to nginx.
      window.location.href = urlParams.get("next");
    }
    history.replace(from);
  };

  return auth.loading ? (
    <div>Checking login status...</div>
  ) : auth.session ? (
    <>
      <p>You are logged in as {auth.session.email}</p>
      <Button onClick={() => history.goBack()} className="mr-2">
        Back
      </Button>
      <LogoutButton />
    </>
  ) : (
    <>
      <Form onSubmit={login}>
        <Form.Group controlId="login-email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="login-password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>

        <Accordion>
          <Button type="submit" disabled={!email || !password} className="mr-2">
            Log in
          </Button>

          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            How to sign up?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <div className="mt-4">
              <p>
                To sign up, please send following information to{" "}
                <a href="mailto:info@5thbizagri.nz?subject=DietDecoder sign up">
                  info@5thbizagri.nz
                </a>
                .
              </p>
              <ul style={{ listStyle: "disc", paddingLeft: "1.5rem" }}>
                <li>Full name</li>
                <li>Company</li>
                <li>Email address (for login)</li>
                <li>Farms that you need access on this system</li>
              </ul>
              <p>We will inform you once user is created.</p>
            </div>
          </Accordion.Collapse>
        </Accordion>
      </Form>
    </>
  );
}
