// import React from "react";
import { useGet } from "./useGet";

export const useFarmTypes = (farmId) => {
  const { data: dairyRaw, isLoadingDairy } = useGet(
    farmId ? `/dairy/farm?farm=${farmId}` : null
  );
  const dairy = dairyRaw?.results?.[0];
  const { data: beefRaw, isLoadingBeef } = useGet(
    farmId ? `/beef/farm?farm=${farmId}` : null
  );
  const beef = beefRaw?.results?.[0];
  const { data: sheepRaw, isLoadingSheep } = useGet(
    farmId ? `/sheep/farm?farm=${farmId}` : null
  );
  const sheep = sheepRaw?.results?.[0];

  return {
    isLoading: isLoadingDairy || isLoadingBeef || isLoadingSheep,
    dairy,
    beef,
    sheep,
  };
};
