import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormField } from "./FormField";
import { FieldArray } from "./FieldArray";
import { NestedObject } from "./NestedObject";

export const FormFieldWrapper = ({
  fieldName,
  fieldSchema,
  uiSchema,
  defaultValue,
  errors,
  fieldId,
  ...rest
}) => {
  const { watch } = useFormContext();
  const [hidden, setHidden] = useState(false);
  useEffect(() => {
    if (uiSchema?.ui?.hidden === true) {
      setHidden(true);
    } else if (
      typeof uiSchema?.ui?.hidden === "object" &&
      uiSchema?.ui?.hidden !== null
    ) {
      const dependency = uiSchema?.ui?.hidden;
      const target = Object.keys(dependency)[0];
      const targetValue = watch(target);
      setHidden(targetValue.toString() === dependency[target].toString());
    }
  }, [uiSchema, fieldName, watch]);

  // Plaintext field is used in frontend (no data from backend)
  if (uiSchema?.ui?.widget === "plaintext" && !fieldSchema) {
    fieldSchema = {};
  }

  if (!fieldSchema) {
    return <div>Schema missing for "{fieldName}" field.</div>;
  }

  if (fieldSchema.type === "field" && fieldSchema.child) {
    fieldSchema = {
      ...fieldSchema.child,
      label: fieldSchema.label,
    };
  }

  // Range field
  if (fieldSchema.type === "list" && fieldSchema.child) {
    fieldSchema = {
      ...fieldSchema,
      type: "nested object",
      children: {
        min: { ...fieldSchema.child, label: `${fieldSchema.label} min` },
        max: { ...fieldSchema.child, label: `${fieldSchema.label} max` },
      },
    };
    if (typeof uiSchema === "string") {
      uiSchema = {
        name: uiSchema,
      };
    }
    uiSchema.items = uiSchema.items || ["min", "max"];
    uiSchema.ui = { inline: true };
    defaultValue = {
      min: defaultValue?.[0],
      max: defaultValue?.[1],
    };
  }

  // Override based on uiSchema
  if (uiSchema?.label) {
    fieldSchema.label = uiSchema.label;
  }
  if (uiSchema?.ui?.widget) {
    fieldSchema.type = uiSchema.ui.widget;
  }
  if (uiSchema?.ui?.choices) {
    fieldSchema.choices = uiSchema.ui.choices;
  }
  if (uiSchema?.ui?.decimal_places) {
    fieldSchema.decimal_places = uiSchema.ui.decimal_places;
  }

  if (uiSchema?.ui?.array) {
    let defaultValueModified = Array.isArray(defaultValue)
      ? [...defaultValue]
      : null;
    let minLength = 1;
    if (uiSchema?.ui?.array_of) {
      // When array_of is specified, create one row for each choice of array_of field
      // Assume it's array of nested object and one of the child field is specified as array_of
      const choices = fieldSchema.children?.[uiSchema.ui.array_of]?.choices;
      defaultValueModified = choices
        ?.map((choice) => {
          const existing = defaultValue?.filter(
            (existing) => existing[uiSchema.ui.array_of] === choice?.value
          );
          if (existing) {
            return existing;
          }
          // Create default value based on fieldSchema
          const keys = Object.keys(fieldSchema.children).filter(
            (key) => key !== "id"
          );
          const value = {};
          keys.forEach((key) => {
            value[key] =
              key === uiSchema.ui.array_of ? choice?.value : undefined;
          });
          return value;
        })
        .flat();
      // Created fields are all deletable.
      // The only use case for this feature is feeding section, where there is a record per
      // stock class by default but user should be able to freely add or delete records.
      minLength = 0;
    }
    return (
      <FieldArray
        fieldName={fieldName}
        fieldSchema={fieldSchema}
        uiSchema={uiSchema}
        defaultValue={defaultValueModified}
        placeholder={uiSchema?.ui?.placeholder}
        errors={errors}
        minLength={minLength}
        {...rest}
      />
    );
  }

  if (fieldSchema.type === "nested object") {
    return (
      <NestedObject
        fieldName={fieldName}
        fieldSchema={fieldSchema}
        uiSchema={uiSchema}
        defaultValue={defaultValue}
        fieldId={fieldId}
        placeholder={uiSchema?.ui?.placeholder}
        errors={errors}
        {...rest}
      />
    );
  }

  return (
    <FormField
      fieldName={fieldName}
      schema={fieldSchema}
      defaultValue={defaultValue}
      errors={errors}
      hideLabel={uiSchema?.ui?.hide_label}
      placeholder={uiSchema?.ui?.placeholder}
      readOnly={uiSchema?.ui?.read_only}
      hidden={hidden}
      {...rest}
    />
  );
};
