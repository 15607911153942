import React from "react";
import { useAuth } from "./useAuth";

export const ChangePasswordLink = () => {
  const auth = useAuth();

  return auth.session ? (
    <a href="/password-change" className="mr-3">
      Change password
    </a>
  ) : null;
};
