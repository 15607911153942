import React from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { useFormContext, useController } from "react-hook-form";
import DatePicker from "react-datepicker";
import classnames from "classnames";
import { getInputDateString } from "services";
import "react-datepicker/dist/react-datepicker.css";
import "./DateField.scss";

export const DateField = ({ schema, name, errors, defaultValue, ...rest }) => {
  const { isEditMode } = useFormContext();
  const registerOptions = {
    required: {
      value: schema.required,
      message: "This field is required",
    },
    setValueAs: (value) => getInputDateString(value),
  };

  const defaultValueDate = defaultValue ? new Date(defaultValue) : null;
  const {
    field: { onChange, onBlur, value, ref },
  } = useController({
    name,
    rules: registerOptions,
    defaultValue: defaultValueDate,
  });

  const wrapperClasses = classnames({
    "d-none": rest.hidden, // `hidden` prop doesn't get propagated so use class to hide
    "is-invalid": !!errors, // to show error messages
  });

  return (
    <Form.Control
      as={DatePicker}
      inputRef={ref}
      // DatePicker props
      onChange={onChange}
      onBlur={onBlur}
      selected={value}
      dateFormat="dd/MM/yyyy"
      disabled={!isEditMode}
      wrapperClassName={wrapperClasses}
      // Form.Control props
      isInvalid={!!errors}
      // html attributes
      autoComplete="off" // disable browser autocomplete
      readOnly={schema.read_only}
    />
  );
};

DateField.propTypes = {
  schema: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  errors: PropTypes.object,
};
