import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { mutate } from "swr";
import { ReportMetadata } from "components/ReportMetadata";
import { useGet } from "components/api/useGet";
import { api } from "components/api/api";
import { handleApiError } from "services";
import { useAlert } from "components/alert/useAlert";
import { ActionSummaryReportTitle } from "../ActionSummaryReportTitle";
import { FormBase } from "components/forms/FormBase";
import { MacroMineralSupplementationTable } from "../MacroMineralSupplementationTable";
import { TraceMineralSupplementationTable } from "../TraceMineralSupplementationTable/TraceMineralSupplementationTable";
import { RitchTextArea } from "components/forms/RitchTextArea";
import "../index.scss";

export const ActionSummaryReportEditPage = () => {
  const [macroComment, setMacroComment] = useState("");
  const [traceComment, setTraceComment] = useState("");

  // Fetch report data
  const { samplingEventId } = useParams();
  const {
    data: reportRaw,
    isLoading: isLoadingReport,
    error: errorLoadingReport,
  } = useGet(
    `/reports/action-summary-report?sampling_event=${samplingEventId}`
  );
  const report = reportRaw?.results?.[0];
  const stockClasses = report?.prescriptions
    ? Object.keys(report?.prescriptions)
    : [];

  // Initialise comments with the report values.
  // Only applies when both comments are empty so that this doesn't overwrite
  // user inputs.
  useEffect(() => {
    if (!macroComment && !traceComment && report) {
      setMacroComment(report.macro_supplementation_comment || "");
      setTraceComment(report.trace_supplementation_comment || "");
    }
  }, [report, macroComment, traceComment]);

  // Form submit
  const alert = useAlert();
  const onSubmit = async (formData) => {
    // Comments update and treatment updates go to separate endpoint,
    // and each treatment update is a separate request.
    // Prepare all the requests and run them in parallel.
    const commentsUpdateRequest = () => {
      const body = {
        sampling_event: samplingEventId,
        macro_supplementation_comment: macroComment,
        trace_supplementation_comment: traceComment,
      };
      return api.put(`/reports/action-summary-report/${report.id}`, body);
    };

    const treatmentUpdateRequests = formData["product-treatment"]
      ? Object.keys(formData["product-treatment"]).map((treatmentKey) => {
          // Extract the id from the key: "id-" + treatmentid
          const treatmentid = treatmentKey.substring(3);
          // Update choices: Convert from
          //   ["is", "df"]
          // to
          //   "choices": {
          //     "is": true,
          //     "mi": false,
          //     "df": true,
          //     "dp": false,
          //     "wt": false
          //   }
          // When there's only one option, react-hook-form returns the value as
          // either `false` when not selected or a string when selected rather
          // than in an array. So check if it's not false first.
          const selected = formData["product-treatment"][treatmentKey];
          const body = {
            choices: {
              is: selected && selected.includes("is"),
              mi: selected && selected.includes("mi"),
              df: selected && selected.includes("df"),
              dp: selected && selected.includes("dp"),
              wt: selected && selected.includes("wt"),
              mb: selected && selected.includes("mb"),
              ll: selected && selected.includes("ll"),
              bo: selected && selected.includes("bo"),
            },
          };
          return api.put(`/product-treatment/${treatmentid}`, body);
        })
      : [];

    try {
      // Run all the requests in parallel
      await Promise.all([commentsUpdateRequest(), ...treatmentUpdateRequests]);
      alert.set({
        message: "Action Summary Report is updated.",
        variant: "success",
      });
      // Bust cache
      mutate(
        `/reports/action-summary-report?sampling_event=${samplingEventId}`
      );
    } catch (error) {
      handleApiError({ apiState: { data: error.response }, alert });
    }
  };

  if (isLoadingReport || !report) {
    return <div>Loading...</div>;
  }
  if (errorLoadingReport) {
    return <div>Failed to load Action Summary Report.</div>;
  }
  return (
    <FormBase
      isEditMode={true}
      submitButtonLabel={"Update"}
      onSubmit={onSubmit}
      isSubmitError={false}
    >
      <div className="report">
        <ActionSummaryReportTitle samplingEventId={samplingEventId} />
        <ReportMetadata samplingEventId={samplingEventId} />

        <section>
          <h2>Macro Mineral Supplementation</h2>

          <h3 className="sr-only">Table</h3>
          <MacroMineralSupplementationTable
            stockClasses={stockClasses}
            samplingEventId={samplingEventId}
            report={report}
            isEdit
          />

          <h3>Comments</h3>
          <RitchTextArea
            name="macro-comment"
            value={macroComment}
            onChange={setMacroComment}
          />
        </section>

        <section>
          <h2>Trace Mineral Supplementation</h2>

          <h3 className="sr-only">Table</h3>
          <TraceMineralSupplementationTable
            stockClasses={stockClasses}
            samplingEventId={samplingEventId}
            report={report}
            isEdit
          />

          <h3>Comments</h3>
          <RitchTextArea
            name="trace-comment"
            value={traceComment}
            onChange={setTraceComment}
          />
        </section>
      </div>
    </FormBase>
  );
};
