import React from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { useGet } from "components/api/useGet";
import { useAuth } from "components/auth/useAuth";
import { reactSelectTheme } from "services";

export const FarmSelect = ({ selected, setFarmId }) => {
  const auth = useAuth();
  const { data: farms } = useGet("/farm?ordering=name");
  const farmOptions =
    Array.isArray(farms?.results) && farms.results.length > 0
      ? farms.results.map((farm) => ({
          value: farm.id,
          label: farm.name,
        }))
      : [];
  const onChangeFarm = (selected) => {
    if (selected) {
      setFarmId(selected.value);
    }
  };

  const label = "Select a farm";
  return (
    <>
      <Form.Group controlId="select-farm">
        <Form.Label>{label}</Form.Label>
        <Select
          options={farmOptions}
          onChange={onChangeFarm}
          placeholder={label}
          aria-label={label}
          theme={reactSelectTheme}
        />
        {auth.session?.is_superuser && (
          <Form.Text>
            <Link to="/farms/new">Add a new farm</Link>
          </Form.Text>
        )}
      </Form.Group>
    </>
  );
};
