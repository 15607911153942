import React from "react";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import Accordion from "react-bootstrap/Accordion";
import { Section } from "./Section";
import { FileUploadForm } from "components/forms/FileUploadForm";

const LabAnalysisUploadForm = ({ samplingEvent, mutate }) => (
  <FileUploadForm
    path={`/sampling-event/${samplingEvent.id}/lab-analysis`}
    label="Select Lab Analysis CSV or XLS file"
    onSuccess={mutate}
    successMessage="Lab Analysis is uploaded."
  />
);

export const LabAnalysisSection = ({ samplingEvent, mutate }) => (
  <Section isDone={samplingEvent.lab_analysis.length > 0}>
    <h2>Lab Analysis</h2>
    {samplingEvent.lab_analysis.length <= 0 && (
      <>
        <p>Please upload Lab Analysis CSV or XLS file.</p>
        <LabAnalysisUploadForm samplingEvent={samplingEvent} mutate={mutate} />
      </>
    )}
    {samplingEvent.lab_analysis.length > 0 && (
      <>
        <p>
          Lab Analysis is uploaded. To update, upload a new file.
        </p>
        <div>
          <a
            href={
              samplingEvent.lab_analysis[samplingEvent.lab_analysis.length - 1]
                .file
            }
          >
            Download existing file
          </a>
        </div>
        <Accordion>
          <Accordion.Toggle
            as="a"
            href="#"
            eventKey="0"
            onClick={(e) => e.preventDefault()}
          >
            Upload a new file
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <>
              <Alert variant="warning">
                <strong>Caution:</strong> Uploading a new file will affect
                Dietary Analysis calculation.
              </Alert>
              <LabAnalysisUploadForm
                samplingEvent={samplingEvent}
                mutate={mutate}
              />
            </>
          </Accordion.Collapse>
        </Accordion>
      </>
    )}
  </Section>
);

LabAnalysisSection.propTypes = {
  samplingEvent: PropTypes.shape({
    id: PropTypes.number.isRequired,
    lab_analysis: PropTypes.array.isRequired,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
};
