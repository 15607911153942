import React from "react";
import Table from "react-bootstrap/Table";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { StockClassDisplayName } from "components/StockClassDisplayName";
import { MINERAL_STATUS } from "services/constants";
import { CopyPhraseButton } from "./CopyPhraseButton";

export const ReportDataTable = ({
  stockClasses,
  reportData,
  unit,
  samplingEventType,
  edit,
}) => {
  return (
    <>
      <h4 className="sr-only">Table</h4>
      <Table bordered size="sm">
        <thead>
          <tr>
            {stockClasses.map((stockClass) => (
              <th key={stockClass}>
                <StockClassDisplayName
                  stockClass={stockClass}
                  samplingEventType={samplingEventType}
                />{" "}
                ({unit})
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {stockClasses.map((stockClass) => {
              const data = reportData?.[stockClass];
              const value = data?.aggregate
                ? Number(data?.aggregate).toFixed(2)
                : "Nil";
              const status = data?.bracket?.status;
              const phrase = data?.bracket?.phrase;
              if (phrase) {
                return (
                  <OverlayTrigger
                    key={stockClass}
                    placement="top"
                    overlay={<Tooltip>{phrase}</Tooltip>}
                  >
                    <td className={value === "Nil" ? "" :
                      (status ? MINERAL_STATUS[status] : "")}>
                      <span>{value}</span>
                      {edit && <CopyPhraseButton phrase={phrase} />}
                    </td>
                  </OverlayTrigger>
                );
              } else {
                return (
                  <td
                    key={stockClass}
                    className={status ? MINERAL_STATUS[status] : ""}
                  >
                    {value}
                  </td>
                );
              }
            })}
          </tr>
        </tbody>
      </Table>
    </>
  );
};
