import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useAlert } from "components/alert/useAlert";
import { useApi } from "components/api/useApi";
import { handleApiError } from "services";

export const StatusToggleButton = ({ samplingEvent, mutate }) => {
  const alert = useAlert();
  const [apiState, setRequest] = useApi();
  const onClick = () => {
    const data = {
      ...samplingEvent,
      status: samplingEvent.status === "o" ? "c" : "o",
    };
    alert.clear();
    setRequest({
      path: `/sampling-event/${samplingEvent.id}`,
      options: {
        method: "PUT",
        data,
      },
    });
  };

  useEffect(() => {
    if (apiState.isLoading) {
      return;
    } else if (apiState.isError) {
      handleApiError({ apiState, alert });
    } else if (apiState.data?.id) {
      alert.set({
        message: "Sampling event status is updated.",
        variant: "success",
      });
      mutate(apiState.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiState]);

  return samplingEvent.status === "o" ? (
    <Button variant="outline-danger" onClick={onClick}>
      Close event
    </Button>
  ) : (
    <Button variant="outline-primary" onClick={onClick}>
      Reopen event
    </Button>
  );
};
