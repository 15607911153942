import React from "react";
import PropTypes from "prop-types";
import { getDisplayDate } from "services";
import { SensitivityFactorForm } from "./SensitivityFactorForm";
import uiSchema from "./uiSchema.json";

export const ReplacementForm = ({
  samplingEvent,
  stockClass,
  analysis,
  schema,
}) => {
  // Data cleaning, only display data so this doesn't affect data passed to backend
  const cleanedAnalysis = {
    ...analysis,
    calving_date: getDisplayDate(analysis.calving_date),
    daily_weight_gain_target: Number(analysis.daily_weight_gain_target).toFixed(
      2
    ),
  };

  return (
    <SensitivityFactorForm
      samplingEvent={samplingEvent}
      stockClass={stockClass}
      analysis={cleanedAnalysis}
      analysisType="replacement_stock_dietary_analysis"
      uiSchemaSet={uiSchema.replacement_stock}
      fieldSchemaAll={schema.replacement_stock_dietary_analysis.child.children}
    />
  );
};

ReplacementForm.propTypes = {
  samplingEvent: PropTypes.object.isRequired,
  stockClass: PropTypes.string.isRequired,
  analysis: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
};
