import React from "react";
import { useFormContext } from "react-hook-form";
import { FormFieldWrapper } from "components/forms/FormFieldWrapper";
import { MINERAL_STATUS } from "services/constants";
import { CopyPhraseButton } from "../CopyPhraseButton";
import { useGet } from "components/api/useGet";

export const ReportSummaryRow = ({ item, schema, uiSchema, report }) => {
  const { watch, errors } = useFormContext();
  const watchStatus = watch(item.status) || report[item.status];

  // Fetch phrases for summary section
  const { data, isLoading } = useGet("/mineral-phrase?section=Summary+Page");
  // Find a phrase of matching status and that applies to the mineral
  const phrase = data?.results?.find(
    (phrase) =>
      phrase.status === watchStatus && phrase.mineral.indexOf(item.symbol) >= 0
  )?.phrase;

  return (
    <tr key={item.status}>
      <th>{item.name}</th>
      <td className={watchStatus ? MINERAL_STATUS[watchStatus] : ""}>
        <FormFieldWrapper
          fieldName={item.status}
          fieldSchema={schema[item.status]}
          uiSchema={uiSchema}
          defaultValue={report[item.status]}
          errors={errors[item.status]}
        />
        {!isLoading && phrase && <CopyPhraseButton phrase={phrase} />}
      </td>
      <td className="text-left">
        <FormFieldWrapper
          fieldName={item.comment}
          fieldSchema={schema[item.comment]}
          uiSchema={uiSchema}
          defaultValue={report[item.comment]}
          errors={errors[item.comment]}
        />
      </td>
    </tr>
  );
};
