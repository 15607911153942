import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useAlert } from "components/alert/useAlert";
import { useApi } from "components/api/useApi";
import { useSchema } from "components/api/useSchema";
import { useGet } from "components/api/useGet";
import { FormBase } from "components/forms/FormBase";
import { FormFields } from "components/forms/FormFields";
import { getTodayString, handleApiError } from "services";
import uiSchema from "./uiSchema.json";

export const QuestionnaireDairyForm = ({
  farmId,
  samplingEventId = "",
  isEditMode = false,
}) => {
  const alert = useAlert();
  const history = useHistory();

  // Fetch schema and existing data
  const { schema, isLoading: isLoadingSchema } = useSchema("/sampling-event");
  const { data, isLoading: isLoadingExistingData, mutate } = useGet(
    samplingEventId ? `/sampling-event/${samplingEventId}` : null
  );
  const existingData = data?.id ? data : null;

  // Override block choices
  // Warn: This is mutating the object
  const { data: farm } = useGet(`/farm/${farmId}`);
  const blockChoices = farm?.blocks?.map((block) => ({
    value: block.id,
    display_name: block.name,
  }));
  uiSchema.fertilisers[0].items[3].ui.choices = blockChoices;

  // Override feed source choices
  // Warn: This is mutating the object
  const { data: feedSources } = useGet("/feed-source");
  const feedSourceChoices = feedSources?.results
    ?.filter((feedSource) => feedSource.dairy === true)
    .map((feedSource) => ({
      value: feedSource.id,
      display_name: feedSource.name,
    }));
  uiSchema.dietary[0].items[0].items[2].ui.choices = feedSourceChoices;

  const [apiState, setRequest] = useApi();
  const onSubmit = (formData) => {
    const data = {
      ...existingData,
      ...formData,
      farm: farmId,
      status: "o",
      dairy: {
        ...existingData?.dairy,
        ...formData.dairy,
        // Quick and dirty way to convert ranges. Fix when time allows.
        weight_range: [
          formData.dairy.weight_range.min,
          formData.dairy.weight_range.max,
        ],
        condition_score_range: [
          formData.dairy.condition_score_range.min,
          formData.dairy.condition_score_range.max,
        ],
      },
      dairy_dietary_analysis: existingData?.dairy_dietary_analysis || [],
      youngstock_dietary_analysis: existingData?.youngstock_dietary_analysis || [],
      replacement_stock_dietary_analysis: existingData?.replacement_stock_dietary_analysis || [],
      sheep_dietary_analysis: existingData?.sheep_dietary_analysis || [],
      lamb_dietary_analysis: existingData?.lamb_dietary_analysis || [],
    };
    alert.clear();
    if (existingData) {
      // Data exists: Update
      setRequest({
        path: `/sampling-event/${existingData.id}`,
        options: {
          method: "PUT",
          data,
        },
      });
    } else {
      // Data doesn't exist: Add
      setRequest({
        path: `/sampling-event`,
        options: {
          method: "POST",
          data,
        },
      });
    }
  };

  useEffect(() => {
    if (apiState.isLoading) {
      return;
    } else if (apiState.isError) {
      handleApiError({ apiState, alert });
    } else if (apiState.data?.id) {
      alert.set({
        message: existingData
          ? "Dairy questionnaire is updated."
          : "Sampling event is added.",
        variant: "success",
      });
      mutate(apiState.data);
      history.push(
        `/sampling-events/${apiState.data.id}${
          existingData ? "/questionnaire" : ""
        }`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiState]);

  const defaultValueAll = existingData || {
    date: getTodayString(),
  };

  return isLoadingSchema || (samplingEventId && isLoadingExistingData) ? (
    <div>Loading form...</div>
  ) : (
    <FormBase
      isEditMode={isEditMode}
      submitButtonLabel={existingData ? "Update" : "Add"}
      onSubmit={onSubmit}
      isSubmitError={apiState.isError}
    >
      <fieldset disabled={!isEditMode}>
        <FormFields
          uiSchemaSet={uiSchema.date}
          fieldSchemaAll={schema}
          defaultValueAll={defaultValueAll}
        />
      </fieldset>

      <fieldset disabled={!isEditMode}>
        <legend>Animal data</legend>
        <FormFields
          uiSchemaSet={uiSchema.animal_data}
          fieldSchemaAll={schema}
          defaultValueAll={defaultValueAll}
        />
      </fieldset>

      <fieldset disabled={!isEditMode}>
        <legend>Milking performance</legend>
        <FormFields
          uiSchemaSet={uiSchema.milking_performance}
          fieldSchemaAll={schema}
          defaultValueAll={defaultValueAll}
        />
      </fieldset>

      <fieldset disabled={!isEditMode}>
        <legend>Reproductive performance</legend>
        <FormFields
          uiSchemaSet={uiSchema.reproductive_performance}
          fieldSchemaAll={schema}
          defaultValueAll={defaultValueAll}
        />
      </fieldset>

      <fieldset disabled={!isEditMode}>
        <legend>General health indicators</legend>
        <FormFields
          uiSchemaSet={uiSchema.general_health_indicators}
          fieldSchemaAll={schema}
          defaultValueAll={defaultValueAll}
        />
      </fieldset>

      <fieldset disabled={!isEditMode}>
        <legend>Mastitis</legend>
        <FormFields
          uiSchemaSet={uiSchema.mastitis}
          fieldSchemaAll={schema}
          defaultValueAll={defaultValueAll}
        />
      </fieldset>

      <fieldset disabled={!isEditMode}>
        <legend>Facial Eczema</legend>
        <FormFields
          uiSchemaSet={uiSchema.facial_eczema}
          fieldSchemaAll={schema}
          defaultValueAll={defaultValueAll}
        />
      </fieldset>

      <fieldset disabled={!isEditMode}>
        <legend>Lameness</legend>
        <FormFields
          uiSchemaSet={uiSchema.lameness}
          fieldSchemaAll={schema}
          defaultValueAll={defaultValueAll}
        />
      </fieldset>

      <fieldset disabled={!isEditMode}>
        <legend>Current direct animal mineral supplementation</legend>
        <FormFields
          uiSchemaSet={uiSchema.supplement_recommendation}
          fieldSchemaAll={schema}
          defaultValueAll={defaultValueAll}
        />
      </fieldset>

      <fieldset disabled={!isEditMode}>
        <legend>Trace mineral supplementation</legend>
        <fieldset>
          <legend>Milking cows</legend>
          <FormFields
            uiSchemaSet={uiSchema.trace_minerals_milking}
            fieldSchemaAll={schema}
            defaultValueAll={defaultValueAll}
          />
        </fieldset>
        <fieldset>
          <legend>Total water or feed trace element details</legend>
          <FormFields
            uiSchemaSet={uiSchema.trace_minerals_details}
            fieldSchemaAll={schema}
            defaultValueAll={defaultValueAll}
          />
        </fieldset>
        <fieldset>
          <legend>R1 heifers</legend>
          <FormFields
            uiSchemaSet={uiSchema.trace_minerals_r1_heifers}
            fieldSchemaAll={schema}
            defaultValueAll={defaultValueAll}
          />
        </fieldset>
        <fieldset>
          <legend>R2 heifers</legend>
          <FormFields
            uiSchemaSet={uiSchema.trace_minerals_r2_heifers}
            fieldSchemaAll={schema}
            defaultValueAll={defaultValueAll}
          />
        </fieldset>
      </fieldset>

      <fieldset disabled={!isEditMode}>
        <legend>Macro mineral supplementation</legend>
        <fieldset>
          <legend>Magnesium supplementation</legend>
          <FormFields
            uiSchemaSet={uiSchema.macro_minerals_magnesium}
            fieldSchemaAll={schema}
            defaultValueAll={defaultValueAll}
          />
        </fieldset>
        <fieldset>
          <legend>Other minerals</legend>
          <FormFields
            uiSchemaSet={uiSchema.macro_minerals_other}
            fieldSchemaAll={schema}
            defaultValueAll={defaultValueAll}
          />
        </fieldset>
      </fieldset>

      <fieldset disabled={!isEditMode}>
        <legend>Fertiliser - last 12 months</legend>
        <FormFields
          uiSchemaSet={uiSchema.fertilisers}
          fieldSchemaAll={schema}
          defaultValueAll={defaultValueAll}
        />
      </fieldset>

      <fieldset disabled={!isEditMode}>
        <legend>Dietary data</legend>
        <FormFields
          uiSchemaSet={uiSchema.dietary}
          fieldSchemaAll={schema}
          defaultValueAll={defaultValueAll}
        />
      </fieldset>

      <fieldset disabled={!isEditMode}>
        <legend>Key areas of focus</legend>
        <FormFields
          uiSchemaSet={uiSchema.areas_of_focus}
          fieldSchemaAll={schema}
          defaultValueAll={defaultValueAll}
        />
      </fieldset>
    </FormBase>
  );
};

QuestionnaireDairyForm.propTypes = {
  farmId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  samplingEventId: PropTypes.string,
  isEditMode: PropTypes.bool,
};
