import React from "react";
import PropTypes from "prop-types";
import { useSchema } from "components/api/useSchema";
import { DairyForm } from "./DairyForm";
import { YoungstockForm } from "./YoungstockForm";
import { ReplacementForm } from "./ReplacementForm";
import { SheepForm } from "./SheepForm";
import { LambForm } from "./LambForm";

export const SensitivityFactor = ({ samplingEvent, stockClass }) => {
  const { schema, isLoading: isLoadingSchema } = useSchema("/sampling-event");

  if (!samplingEvent) {
    return <div>samplingEvent prop missing</div>;
  }
  if (!stockClass) {
    return <div>stockClass prop missing</div>;
  }

  const dairyAnalysis =
    samplingEvent.dairy_dietary_analysis.length > 0 &&
    samplingEvent.dairy_dietary_analysis.find(
      (analysis) => analysis.stock_class === stockClass
    );
  const youngstockAnalysis =
    samplingEvent.youngstock_dietary_analysis.length > 0 &&
    samplingEvent.youngstock_dietary_analysis.find(
      (analysis) => analysis.stock_class === stockClass
    );
  const replacementAnalysis =
    samplingEvent.replacement_stock_dietary_analysis.length > 0 &&
    samplingEvent.replacement_stock_dietary_analysis.find(
      (analysis) => analysis.stock_class === stockClass
    );
  const sheepAnalysis =
    samplingEvent.sheep_dietary_analysis.length > 0 &&
    samplingEvent.sheep_dietary_analysis.find(
      (analysis) => analysis.stock_class === stockClass
    );
  const lambAnalysis =
    samplingEvent.lamb_dietary_analysis.length > 0 &&
    samplingEvent.lamb_dietary_analysis.find(
      (analysis) => analysis.stock_class === stockClass
    );

  return isLoadingSchema ? (
    <div>Loading...</div>
  ) : dairyAnalysis ? (
    <DairyForm
      samplingEvent={samplingEvent}
      stockClass={stockClass}
      analysis={dairyAnalysis}
      schema={schema}
    />
  ) : youngstockAnalysis ? (
    <YoungstockForm
      samplingEvent={samplingEvent}
      stockClass={stockClass}
      analysis={youngstockAnalysis}
      schema={schema}
    />
  ) : replacementAnalysis ? (
    <ReplacementForm
      samplingEvent={samplingEvent}
      stockClass={stockClass}
      analysis={replacementAnalysis}
      schema={schema}
    />
  ) : sheepAnalysis ? (
    <SheepForm
      samplingEvent={samplingEvent}
      stockClass={stockClass}
      analysis={sheepAnalysis}
      schema={schema}
    />
  ) : lambAnalysis ? (
    <LambForm
      samplingEvent={samplingEvent}
      stockClass={stockClass}
      analysis={lambAnalysis}
      schema={schema}
    />
  ) : null;
};

SensitivityFactor.propTypes = {
  samplingEvent: PropTypes.object.isRequired,
  stockClass: PropTypes.string.isRequired,
};
