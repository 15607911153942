import React from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import { NON_MINERALS, MACRO_MINERALS, TRACE_MINERALS } from "./constants";
import { MINERAL_STATUS, MINERAL_STATUS_LABELS } from "services/constants";
import "./index.scss";

const ReportSummaryRow = ({ report, item }) => {
  const status = report[item.status];
  // Ugly kludge for "high risk" being two words.
  return (
    <tr>
      <th>{item.name}</th>
      <td className={status ? MINERAL_STATUS[status] : ""}>
        {status ? MINERAL_STATUS_LABELS[status] : "Not set"}
      </td>
      <td className="text-left">{report[item.comment]}</td>
    </tr>
  );
};

export const ReportSummaryTable = ({ report }) => (
  <Table bordered size="sm" className="report-summary-table">
    <thead>
      <tr>
        <th className="thead-element">Element</th>
        <th className="thead-status">Status</th>
        <th>Comment</th>
      </tr>
    </thead>

    <tbody>
      <tr>
        <th colSpan={3}>Non Mineral Parameters</th>
      </tr>
      {NON_MINERALS.map((item) => (
        <ReportSummaryRow key={item.status} report={report} item={item} />
      ))}

      <tr>
        <th colSpan={3}>Macro Minerals</th>
      </tr>
      {MACRO_MINERALS.map((item) => (
        <ReportSummaryRow key={item.status} report={report} item={item} />
      ))}

      <tr>
        <th colSpan={3}>Trace Minerals</th>
      </tr>
      {TRACE_MINERALS.map((item) => (
        <ReportSummaryRow key={item.status} report={report} item={item} />
      ))}
    </tbody>
  </Table>
);

ReportSummaryTable.propTypes = {
  report: PropTypes.object.isRequired,
};
