import React from "react";
import PropTypes from "prop-types";
import { SensitivityFactorForm } from "./SensitivityFactorForm";
import uiSchema from "./uiSchema.json";

export const DairyForm = ({ samplingEvent, stockClass, analysis, schema }) => {
  const useDryCowSchema =
    stockClass === "dry_cow" || (samplingEvent.beef && stockClass !== "ma_cows");
  const uiSchemaSet = useDryCowSchema ? uiSchema.dairy_dry : uiSchema.dairy;

  return (
    <SensitivityFactorForm
      samplingEvent={samplingEvent}
      stockClass={stockClass}
      analysis={analysis}
      analysisType="dairy_dietary_analysis"
      uiSchemaSet={uiSchemaSet}
      fieldSchemaAll={schema.dairy_dietary_analysis.child.children}
    />
  );
};

DairyForm.propTypes = {
  samplingEvent: PropTypes.object.isRequired,
  stockClass: PropTypes.string.isRequired,
  analysis: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
};
