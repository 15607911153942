import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useApi } from "components/api/useApi";
import { useAlert } from "components/alert/useAlert";
import { handleApiError } from "services";

export const FileUploadForm = ({ path, label, onSuccess, successMessage }) => {
  const [apiState, setRequest] = useApi();
  const [file, setFile] = useState(null);
  const csvUpload = (e) => {
    e.preventDefault();
    if (!file) {
      return null;
    }
    const data = new FormData();
    data.append("file", file);
    setRequest({
      path,
      options: {
        method: "POST",
        data,
      },
    });
  };

  const alert = useAlert();
  useEffect(() => {
    if (apiState.isLoading) {
      return;
    } else if (apiState.isError) {
      handleApiError({ apiState, alert });
    } else if (apiState.data?.filepath) {
      alert.set({
        message: successMessage,
        variant: "success",
      });
      onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiState]);

  return (
    <form onSubmit={csvUpload}>
      <Form.File
        id="lab-analysis-upload"
        label={file ? file.name : label}
        custom
        onChange={(e) => setFile(e.target.files[0])}
        className="mb-2"
      />
      <Button type="submit" disabled={!file} className="mb-3">
        Upload
      </Button>
    </form>
  );
};

FileUploadForm.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  successMessage: PropTypes.string.isRequired,
};
