import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { mutate } from "swr";
import { ReportMetadata } from "components/ReportMetadata";
import { useGet } from "components/api/useGet";
import { useApi } from "components/api/useApi";
import { handleApiError } from "services";
import { useSamplingEvent } from "components/api/useSamplingEvent";
import { useSchema } from "components/api/useSchema";
import { useAlert } from "components/alert/useAlert";
import { FormBase } from "components/forms/FormBase";
import { RitchTextArea } from "components/forms/RitchTextArea";
import { getSeason } from "services";
import { DietaryBreakdownTable } from "../DietaryBreakdownTable";
import { ReportSummaryTableEdit } from "../ReportSummaryTable/ReportSummaryTableEdit";
import { KeyDietaryScale, KeyDietaryScaleNonmineral } from "../KeyDietaryScale";
import { NonMinerals } from "../NonMinerals";
import { MacroMinerals } from "../MacroMinerals";
import { TraceMinerals } from "../TraceMinerals";
import "../index.scss";

export const DietaryCommentaryReportEditPage = () => {
  const alert = useAlert();
  const { samplingEventId } = useParams();
  const {
    isLoading: isLoadingSe,
    samplingEvent,
    type: samplingEventType,
  } = useSamplingEvent(samplingEventId);
  const { data: reportRaw, isLoading: isLoadingReport } = useGet(
    `/reports/dietary-commentary-report?sampling_event=${samplingEventId}`
  );
  const report = reportRaw?.results?.[0];

  const { schema, isLoading: isLoadingSchema } = useSchema(
    "/reports/dietary-commentary-report"
  );

  const [nonMineralBackground, setNonMineralBackground] = useState("");
  const [macroBackground, setMacroBackground] = useState("");
  const [traceBackground, setTraceBackground] = useState("");
  useEffect(() => {
    if (
      !nonMineralBackground &&
      !macroBackground &&
      !traceBackground &&
      report
    ) {
      setNonMineralBackground(report.nonmineral_background);
      setMacroBackground(report.macro_background);
      setTraceBackground(report.trace_background);
    }
  }, [report, nonMineralBackground, macroBackground, traceBackground]);

  const [apiState, setRequest] = useApi();
  const onSubmit = (formData) => {
    const data = {
      id: report.id,
      sampling_event: report.sampling_event,
      ...formData,
    };
    setRequest({
      path: `/reports/dietary-commentary-report/${report.id}`,
      options: {
        method: "PUT",
        data,
      },
    });
  };
  useEffect(() => {
    if (apiState.isLoading) {
      return;
    } else if (apiState.isError) {
      handleApiError({ apiState, alert });
    } else if (apiState.data) {
      alert.set({
        message: "Dietary Commentary Report is updated.",
        variant: "success",
      });
      // Bust cache
      mutate(
        `/reports/dietary-commentary-report?sampling_event=${samplingEvent.id}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiState]);

  const isLoading = isLoadingSe || isLoadingReport || isLoadingSchema;
  return isLoading ? (
    <div>Loading...</div>
  ) : !report ? (
    <div>Dietary Commentary Report for this sampling event does not exist.</div>
  ) : (
    <FormBase
      isEditMode={true}
      submitButtonLabel={"Update"}
      onSubmit={onSubmit}
      isSubmitError={false}
    >
      <div className="report">
        <h1>Dietary Commentary for {getSeason(samplingEvent.date)}</h1>

        <ReportMetadata samplingEventId={samplingEventId} />

        <DietaryBreakdownTable
          report={report}
          samplingEventType={samplingEventType}
        />

        <section>
          <h2>Report Summary</h2>
          <ReportSummaryTableEdit report={report} schema={schema} />
        </section>

        <section>
          <h2>Non-Mineral Dietary Composition</h2>

          <KeyDietaryScaleNonmineral />

          <div className="two-columns">
            <h3>Background</h3>
            <RitchTextArea
              name="nonmineral_background"
              value={nonMineralBackground}
              onChange={setNonMineralBackground}
            />

            <NonMinerals
              report={report}
              samplingEventType={samplingEventType}
              edit
            />
          </div>
        </section>

        <section>
          <h2>Macro Minerals Profile</h2>

          <KeyDietaryScale />

          <div className="two-columns">
            <h3>Background</h3>
            <RitchTextArea
              name="macro_background"
              value={macroBackground}
              onChange={setMacroBackground}
            />

            <MacroMinerals
              report={report}
              samplingEventType={samplingEventType}
              edit
            />
          </div>
        </section>

        <section>
          <h2>Trace Minerals Profile</h2>

          <KeyDietaryScale />

          <div className="two-columns">
            <h3>Background</h3>
            <RitchTextArea
              name="trace_background"
              value={traceBackground}
              onChange={setTraceBackground}
            />

            <TraceMinerals
              report={report}
              samplingEventType={samplingEventType}
              edit
            />
          </div>
        </section>
      </div>
    </FormBase>
  );
};
