import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useAlert } from "components/alert/useAlert";
import { useApi } from "components/api/useApi";
import { useSchema } from "components/api/useSchema";
import { useGet } from "components/api/useGet";
import { FormBase } from "components/forms/FormBase";
import { FormFields } from "components/forms/FormFields";
import { getTodayString, handleApiError } from "services";
import uiSchema from "./uiSchema.json";

export const QuestionnaireBeefForm = ({
  farmId,
  samplingEventId = "",
  isEditMode = false,
}) => {
  const alert = useAlert();
  const history = useHistory();

  // Fetch schema and existing data
  const { schema, isLoading: isLoadingSchema } = useSchema("/sampling-event");
  const { data, isLoading: isLoadingExistingData, mutate } = useGet(
    samplingEventId ? `/sampling-event/${samplingEventId}` : null
  );
  const existingData = data?.id ? data : null;

  // Override block choices
  const { data: farm } = useGet(`/farm/${farmId}`);
  const blockChoices = farm?.blocks?.map((block) => ({
    value: block.id,
    display_name: block.name,
  }));
  uiSchema.fertilisers[0].items[3].ui.choices = blockChoices;

  // Override feed source choices
  const { data: feedSources } = useGet("/feed-source");
  const feedSourceChoices = feedSources?.results
    ?.filter((feedSource) => feedSource.beef === true)
    .map((feedSource) => ({
      value: feedSource.id,
      display_name: feedSource.name,
    }));
  // Quick and dirty. Fix when time allows.
  uiSchema.breeding_feeding[0].items[0].items[0].items[2].ui.choices = feedSourceChoices;
  uiSchema.finishing_feeding[0].items[0].items[0].items[2].ui.choices = feedSourceChoices;

  const [apiState, setRequest] = useApi();
  const onSubmit = (formData) => {
    const data = {
      ...existingData,
      ...formData,
      farm: farmId,
      status: "o",
      beef: {
        ...existingData?.beef,
        ...formData.beef,
        // Quick and dirty way to convert ranges. Fix when time allows.
        breeding: {
          ...existingData?.beef?.breeding,
          ...formData.beef.breeding,
          stock: formData.beef.breeding.stock.map((stock) => ({
            ...stock,
            weight_range: [stock.weight_range.min, stock.weight_range.max],
          })),
          weight_range: [
            formData.beef.breeding.weight_range.min,
            formData.beef.breeding.weight_range.max,
          ],
        },
        finishing: {
          ...existingData?.beef?.finishing,
          ...formData.beef.finishing,
        },
      },
      dairy_dietary_analysis: existingData?.dairy_dietary_analysis || [],
      youngstock_dietary_analysis: existingData?.youngstock_dietary_analysis || [],
      replacement_stock_dietary_analysis: existingData?.replacement_stock_dietary_analysis || [],
      sheep_dietary_analysis: existingData?.sheep_dietary_analysis || [],
      lamb_dietary_analysis: existingData?.lamb_dietary_analysis || [],
    };
    alert.clear();
    if (existingData) {
      // Data exists: Update
      setRequest({
        path: `/sampling-event/${existingData.id}`,
        options: {
          method: "PUT",
          data,
        },
      });
    } else {
      // Data doesn't exist: Add
      setRequest({
        path: `/sampling-event`,
        options: {
          method: "POST",
          data,
        },
      });
    }
  };

  const defaultValueAll = existingData || {
    date: getTodayString(),
  };

  useEffect(() => {
    if (apiState.isLoading) {
      return;
    } else if (apiState.isError) {
      handleApiError({ apiState, alert });
    } else if (apiState.data?.id) {
      alert.set({
        message: existingData
          ? "Beef questionnaire is updated."
          : "Sampling event is added.",
        variant: "success",
      });
      mutate(apiState.data);
      history.push(
        `/sampling-events/${apiState.data.id}${
          existingData ? "/questionnaire" : ""
        }`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiState]);

  return isLoadingSchema || (samplingEventId && isLoadingExistingData) ? (
    <div>Loading form...</div>
  ) : (
    <FormBase
      isEditMode={isEditMode}
      submitButtonLabel={existingData ? "Update" : "Add"}
      onSubmit={onSubmit}
      isSubmitError={apiState.isError}
    >
      <fieldset disabled={!isEditMode}>
        <FormFields
          uiSchemaSet={uiSchema.date}
          fieldSchemaAll={schema}
          defaultValueAll={defaultValueAll}
        />
      </fieldset>

      <fieldset disabled={!isEditMode}>
        <legend>Fertiliser - last 12 months</legend>
        <FormFields
          uiSchemaSet={uiSchema.fertilisers}
          fieldSchemaAll={schema}
          defaultValueAll={defaultValueAll}
        />
      </fieldset>

      <fieldset disabled={!isEditMode}>
        <legend>Breeding stock</legend>
        <fieldset>
          <legend>Breeding stock</legend>
          <FormFields
            uiSchemaSet={uiSchema.breeding_stock}
            fieldSchemaAll={schema}
            defaultValueAll={defaultValueAll}
          />
        </fieldset>
        <fieldset>
          <legend>Reproduction data</legend>
          <FormFields
            uiSchemaSet={uiSchema.breeding_reproduction}
            fieldSchemaAll={schema}
            defaultValueAll={defaultValueAll}
          />
        </fieldset>
        <fieldset>
          <legend>Animal health plan</legend>
          <FormFields
            uiSchemaSet={uiSchema.breeding_health_plan}
            fieldSchemaAll={schema}
            defaultValueAll={defaultValueAll}
          />
        </fieldset>
        <fieldset>
          <legend>Animal health issues?</legend>
          <FormFields
            uiSchemaSet={uiSchema.breeding_health_issues}
            fieldSchemaAll={schema}
            defaultValueAll={defaultValueAll}
          />
        </fieldset>
        <fieldset>
          <legend>Feeding</legend>
          <FormFields
            uiSchemaSet={uiSchema.breeding_feeding}
            fieldSchemaAll={schema}
            defaultValueAll={defaultValueAll}
          />
        </fieldset>
      </fieldset>

      <fieldset disabled={!isEditMode}>
        <legend>Finishing / trading cattle</legend>
        <fieldset>
          <legend>Finishing / trading cattle</legend>
          <FormFields
            uiSchemaSet={uiSchema.finishing_cattle}
            fieldSchemaAll={schema}
            defaultValueAll={defaultValueAll}
          />
        </fieldset>
        <fieldset>
          <legend>Feeding</legend>
          <FormFields
            uiSchemaSet={uiSchema.finishing_feeding}
            fieldSchemaAll={schema}
            defaultValueAll={defaultValueAll}
          />
        </fieldset>
        <fieldset>
          <legend>Growth rates</legend>
          <p>Stock classes that are selected in "Finishing / trading cattle" will be listed below.</p>
          <FormFields
            uiSchemaSet={uiSchema.finishing_growth_rates}
            fieldSchemaAll={schema}
            defaultValueAll={defaultValueAll}
          />
        </fieldset>
        <fieldset>
          <legend>Animal health plan</legend>
          <FormFields
            uiSchemaSet={uiSchema.finishing_health_plan}
            fieldSchemaAll={schema}
            defaultValueAll={defaultValueAll}
          />
        </fieldset>
      </fieldset>

      <fieldset disabled={!isEditMode}>
        <legend>Key areas of focus</legend>
        <FormFields
          uiSchemaSet={uiSchema.areas_of_focus}
          fieldSchemaAll={schema}
          defaultValueAll={defaultValueAll}
        />
      </fieldset>
    </FormBase>
  );
};

QuestionnaireBeefForm.propTypes = {
  farmId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  samplingEventId: PropTypes.string,
  isEditMode: PropTypes.bool,
};
