import React from "react";
import { ElementProfile } from "./ElementProfile";

const macroMinerals = [
  {
    name: "Phosphorus (P)",
    reportKey: "p_percent",
    code: "p",
  },
  {
    name: "Potassium (K)",
    reportKey: "k_percent",
    code: "k",
  },
  {
    name: "Sulphur (S)",
    reportKey: "s_percent",
    code: "s",
  },
  {
    name: "Calcium (Ca)",
    reportKey: "ca_percent",
    code: "ca",
  },
  {
    name: "Magnesium (Mg)",
    reportKey: "mg_percent",
    code: "mg",
  },
  {
    name: "Sodium (Na)",
    reportKey: "na_percent",
    code: "na",
  },
  {
    name: "Chlorine (Cl)",
    reportKey: "cl_percent",
    code: "cl",
  },
];

export const MacroMinerals = ({ report, samplingEventType, edit = false }) => {
  return macroMinerals.map((item) => (
    <ElementProfile
      key={item.reportKey}
      item={item}
      category="macro"
      report={report}
      unit="%DM"
      samplingEventType={samplingEventType}
      edit={edit}
    />
  ));
};
