import React from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import { StockClassDisplayName } from "components/StockClassDisplayName";
import { getStockCountUnit } from "services/samplingEvent";

export const DietaryBreakdownTable = ({ report, samplingEventType }) => {
  const stockClasses = report.report_data?.stock_classes ?? [];
  const feed = report.report_data?.feed ?? { feed_type: {} };
  const feedSources = Object.keys(feed.feed_type);

  const total = {};
  feedSources.forEach((feedSource) => {
    stockClasses.forEach((stockClass) => {
      if (feed.feed_type[feedSource][stockClass]) {
        if (total[stockClass]) {
          total[stockClass] += feed.feed_type[feedSource][stockClass];
        } else {
          total[stockClass] = feed.feed_type[feedSource][stockClass];
        }
      }
    });
  });

  return (
    <Table bordered size="sm">
      <thead>
        <tr>
          <th colSpan={stockClasses.length + 1}>Dietary Breakdown</th>
        </tr>
        <tr>
          <th>Mobs</th>
          {stockClasses.map((stockClass) => (
            <th key={stockClass}>
              <StockClassDisplayName
                stockClass={stockClass}
                samplingEventType={samplingEventType}
              />
            </th>
          ))}
        </tr>
        <tr>
          <th>Feed Type</th>
          <th colSpan={stockClasses.length}>
            DM Offered (kg/{getStockCountUnit(samplingEventType)}/day)
          </th>
        </tr>
      </thead>
      <tbody>
        {feedSources.map((feedSource, i) => (
          <tr key={i}>
            <td>{feedSource}</td>
            {stockClasses.map((stockClass) => (
              <td key={stockClass}>
                {feed.feed_type[feedSource][stockClass] || 0}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th>Total DM (kg/{getStockCountUnit(samplingEventType)}/day)</th>
          {stockClasses.map((stockClass) => (
            <th key={stockClass}>{total[stockClass]}</th>
          ))}
        </tr>
      </tfoot>
    </Table>
  );
};

DietaryBreakdownTable.propTypes = {
  report: PropTypes.object.isRequired,
};
