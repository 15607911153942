import React from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import { ReportSummaryRow } from "./ReportSummaryRow";
import { NON_MINERALS, MACRO_MINERALS, TRACE_MINERALS } from "./constants";
import "./index.scss";

export const ReportSummaryTableEdit = ({ report, schema }) => {
  const uiSchema = {
    ui: { hide_label: true },
  };

  return (
    <Table bordered size="sm" className="report-summary-table">
      <thead>
        <tr>
          <th className="thead-element">Element</th>
          <th className="thead-status">Status</th>
          <th>Comment</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <th colSpan={3}>Non Mineral Parameters</th>
        </tr>
        {NON_MINERALS.map((item) => (
          <ReportSummaryRow
            key={item.status}
            item={item}
            schema={schema}
            uiSchema={uiSchema}
            report={report}
          />
        ))}

        <tr>
          <th colSpan={3}>Macro Minerals</th>
        </tr>
        {MACRO_MINERALS.map((item) => (
          <ReportSummaryRow
            key={item.status}
            item={item}
            schema={schema}
            uiSchema={uiSchema}
            report={report}
          />
        ))}

        <tr>
          <th colSpan={3}>Trace Minerals</th>
        </tr>
        {TRACE_MINERALS.map((item) => (
          <ReportSummaryRow
            key={item.status}
            item={item}
            schema={schema}
            uiSchema={uiSchema}
            report={report}
          />
        ))}
      </tbody>
    </Table>
  );
};

ReportSummaryTableEdit.propTypes = {
  report: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
};
