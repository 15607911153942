import { useEffect } from "react";
import useSWR from "swr";
import { api } from "./api";
import { useAlert } from "components/alert/useAlert";
import { handleApiError } from "services";

const fetcher = (path) =>
  api(path, {
    method: "GET",
  }).then((res) => res.data);

export function useGet(path) {
  const { data, error, mutate } = useSWR(path, fetcher);
  const alert = useAlert();
  useEffect(() => {
    if (!error) return null;
    if (error.response) {
      const apiState = { data: error.response };
      handleApiError({ apiState, alert });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return {
    data,
    isLoading: !error && !data,
    error,
    mutate,
  };
}
