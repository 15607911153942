import React from "react";
import PropTypes from "prop-types";
import { useSamplingEvent } from "components/api/useSamplingEvent";
import { getSeason } from "services";

export const ActionSummaryReportTitle = ({ samplingEventId }) => {
  // Fetch Sampling event date to display title
  const {
    samplingEvent,
    isLoading,
    error,
  } = useSamplingEvent(samplingEventId);

  return (
    <h1>
      Action Summary
      {!isLoading && !error && (
        <> for {getSeason(samplingEvent?.date)}</>
      )}
    </h1>
  );
};

ActionSummaryReportTitle.propTypes = {
  samplingEventId: PropTypes.string.isRequired,
};
