import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { mutate } from "swr";
import { useAlert } from "components/alert/useAlert";
import { useApi } from "components/api/useApi";
import { handleApiError } from "services";
import { FormBase } from "components/forms/FormBase";
import { FormFields } from "components/forms/FormFields";

export const SensitivityFactorForm = ({
  samplingEvent,
  stockClass,
  analysis,
  analysisType,
  uiSchemaSet,
  fieldSchemaAll,
}) => {
  const alert = useAlert();
  const [apiState, setRequest] = useApi();

  const onSubmit = (formData) => {
    const data = { ...samplingEvent };
    // Replace analysis
    const index = data[analysisType].findIndex(
      (analysis) => analysis.id === formData.id
    );
    data[analysisType][index] = {
      ...samplingEvent[analysisType][index],
      ...formData,
      sampling_event_type: analysis.sampling_event_type,
      stock_class: analysis.stock_class,
    };
    alert.clear();
    setRequest({
      path: `/sampling-event/${samplingEvent.id}`,
      options: {
        method: "PUT",
        data,
      },
    });
  };

  useEffect(() => {
    if (apiState.isLoading) {
      return;
    } else if (apiState.isError) {
      handleApiError({ apiState, alert });
    } else if (apiState.data?.id) {
      alert.set({
        message: "Analysis is updated.",
        variant: "success",
      });
      mutate(`/sampling-event/${samplingEvent.id}`);
      mutate(`/reports/dietary-analysis-report/${samplingEvent.id}/${stockClass}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiState]);

  return (
    <FormBase isEditMode onSubmit={onSubmit} noButtons>
      <fieldset>
        <legend>Sensitivity Factor</legend>
        <FormFields
          uiSchemaSet={uiSchemaSet}
          fieldSchemaAll={fieldSchemaAll}
          defaultValueAll={analysis}
          inline
        />
      </fieldset>
      <Button type="submit">Update analysis</Button>
    </FormBase>
  );
};

SensitivityFactorForm.propTypes = {
  samplingEvent: PropTypes.object.isRequired,
  analysis: PropTypes.object.isRequired,
  analysisType: PropTypes.string.isRequired,
  uiSchemaSet: PropTypes.array.isRequired,
  fieldSchemaAll: PropTypes.object.isRequired,
};
