import React from "react";
import BSBreadcrumb from "react-bootstrap/Breadcrumb";
import { Route, Link, useParams, useRouteMatch } from "react-router-dom";
import { useGet } from "components/api/useGet";
import { useSamplingEvent } from "components/api/useSamplingEvent";

const BreadcrumbItemFarm = () => {
  const { farmId } = useParams();
  const { data: farm, isLoading } = useGet(
    !Number.isNaN(Number(farmId)) ? `/farm/${farmId}` : null
  );
  return isLoading || !farm?.name ? null : (
    <BSBreadcrumb.Item active>{farm.name}</BSBreadcrumb.Item>
  );
};

const BreadcrumbItemSamplingEvent = () => {
  const { samplingEventId } = useParams();
  const { url, isExact } = useRouteMatch();
  const { isLoading, title } = useSamplingEvent(samplingEventId);
  return isLoading ? null : (
    <BSBreadcrumb.Item linkAs={Link} linkProps={{ to: url }} active={isExact}>
      {title}
    </BSBreadcrumb.Item>
  );
};

export const Breadcrumb = () => {
  return (
    <BSBreadcrumb>
      <Route
        path="/sampling-events"
        render={({ match }) => (
          <BSBreadcrumb.Item
            linkAs={Link}
            linkProps={{ to: match.url }}
            active={match.isExact}
          >
            Home
          </BSBreadcrumb.Item>
        )}
      />
      <Route path="/sampling-events/:samplingEventId">
        <BreadcrumbItemSamplingEvent />
      </Route>
      <Route path="/sampling-events/:samplingEventId/questionnaire">
        <BSBreadcrumb.Item active>Questionnaire</BSBreadcrumb.Item>
      </Route>
      <Route path="/sampling-events/:samplingEventId/analysis">
        <BSBreadcrumb.Item active>Dietary Analysis Report</BSBreadcrumb.Item>
      </Route>
      <Route path="/sampling-events/:samplingEventId/commentary">
        <BSBreadcrumb.Item active>Dietary Commentary Report</BSBreadcrumb.Item>
      </Route>
      <Route path="/sampling-events/:samplingEventId/action">
        <BSBreadcrumb.Item active>Action Summary Report</BSBreadcrumb.Item>
      </Route>

      <Route
        path="/farms"
        render={({ match }) => (
          <>
            <BSBreadcrumb.Item
              linkAs={Link}
              linkProps={{ to: "/sampling-events" }}
            >
              Home
            </BSBreadcrumb.Item>
            <BSBreadcrumb.Item
              linkAs={Link}
              linkProps={{ to: match.url }}
              active={match.isExact}
            >
              Farms
            </BSBreadcrumb.Item>
          </>
        )}
      />
      <Route path="/farms/:farmId">
        <BreadcrumbItemFarm />
      </Route>
    </BSBreadcrumb>
  );
};
