import React from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import { StockClassDisplayName } from "components/StockClassDisplayName";
import { PRODUCT_ELEMENT_MAP } from "services/constants";

const MILKER_CLASSES = ["early_milkers", "peak_milkers", "late_milkers"];

const getMilkerClass = (stockClasses) => {
  let theMilker = "";
  MILKER_CLASSES.forEach((milker) => {
    if (!theMilker && stockClasses.includes(milker)) {
      theMilker = milker;
    }
  });
  return theMilker;
};

const NoMilkerMessage = () => (
  <Alert variant="warning">
    This section is for one of these stock classes: Early Milkers, Peak Milkers,
    or Late Milkers.
    <br />
    No prescriptions found for one of the above stock classes.
  </Alert>
);

const MilkerDisplay = ({ theMilker }) => (
  <p>
    Prescription for{" "}
    <strong>
      <StockClassDisplayName stockClass={theMilker} samplingEventType="dairy" />
    </strong>
  </p>
);

const DairyProductRow = ({ product, productLookups, theMilker }) => {
  const isPrescribed = !!product.base_product_amounts[theMilker];
  if (!isPrescribed) {
    return null;
  }

  const productLookup = productLookups.find(
    (lookup) => lookup.id === product.productid
  );
  const percentageKey = PRODUCT_ELEMENT_MAP.find(
    (map) => map.element === product.element
  )?.key;

  let prescriptionRateCell = "Mineral product lookup not defined.";
  if (productLookup && percentageKey && productLookup[percentageKey]) {
    const { amount, unit } = product.base_product_amounts[theMilker];
    const percentage = productLookup[percentageKey];
    const prescriptionRate = (Number(amount) * Number(percentage) / 100).toFixed(2);
    prescriptionRateCell = `${prescriptionRate} ${unit}`;
  }

  return (
    <tr>
      <td>{product.element}</td>
      <td>{prescriptionRateCell}</td>
      <td>{product.name}</td>
    </tr>
  );
};

export const DairyTraceMineralSupplementationTable = ({
  stockClasses,
  traceProductTable,
  productLookups,
}) => {
  // Find the milker class in the sampling event
  // Assumption: Only one milker class in the sampling event
  const theMilker = getMilkerClass(stockClasses);
  if (!theMilker) {
    return <NoMilkerMessage />;
  }
  return (
    <>
      <MilkerDisplay theMilker={theMilker} />
      <Table bordered size="sm">
        <thead>
          <tr>
            <th>Element</th>
            <th>Elemental Prescription Rate (mg)</th>
            <th>Suggested Product</th>
          </tr>
        </thead>
        <tbody>
          {traceProductTable?.map((product) => (
            <DairyProductRow
              key={product.productid}
              product={product}
              productLookups={productLookups}
              theMilker={theMilker}
            />
          ))}
        </tbody>
      </Table>
    </>
  );
};

DairyTraceMineralSupplementationTable.propTypes = {
  stockClasses: PropTypes.array.isRequired,
  traceProductTable: PropTypes.array.isRequired,
  productLookups: PropTypes.array.isRequired,
};
