import React from "react";
import PropTypes from "prop-types";
import { useSamplingEvent } from "components/api/useSamplingEvent";
import { useGet } from "components/api/useGet";
import { DairyTraceMineralSupplementationTable } from "./DairyTraceMineralSupplementationTable";
import { NonDairyTraceMineralSupplementationTable } from "./NonDairyTraceMineralSupplementationTable";

export const TraceMineralSupplementationTable = ({
  stockClasses,
  samplingEventId,
  report,
  isEdit = false,
}) => {
  const {
    isLoading: isLoadingSe,
    type: samplingEventType,
    error: errorLoadingSe,
  } = useSamplingEvent(samplingEventId);

  const {
    data: productLookupsRaw,
    isLoading: isLoadingProductLookups,
    error: errorLoadingProductLookups,
  } = useGet("/mineral-product");
  const productLookups = productLookupsRaw?.results;

  if (isLoadingSe || isLoadingProductLookups) {
    return <div>Loading...</div>;
  }
  if (errorLoadingSe || errorLoadingProductLookups) {
    return <div>Failed to load table data.</div>;
  }
  return samplingEventType === "dairy" ? (
    <DairyTraceMineralSupplementationTable
      stockClasses={stockClasses}
      traceProductTable={report?.trace_product_table}
      productLookups={productLookups}
    />
  ) : (
    <NonDairyTraceMineralSupplementationTable
      stockClasses={stockClasses}
      animalType={report.animal_type}
      samplingEventType={samplingEventType}
      traceProductTable={report?.trace_product_table}
      productLookups={productLookups}
      isEdit={isEdit}
    />
  );
};

TraceMineralSupplementationTable.propTypes = {
  stockClasses: PropTypes.array.isRequired,
  samplingEventId: PropTypes.string.isRequired,
  report: PropTypes.object.isRequired,
  isEdit: PropTypes.bool,
};
