import { useGet } from "./useGet";
import { getSamplingEventTitle, getSamplingEventType } from "services";

export const useSamplingEvent = (samplingEventId) => {
  const {
    data: samplingEvent,
    isLoading: isLoadingSamplingEvent,
    error: errorSamplingEvent,
    mutate,
  } = useGet(
    !Number.isNaN(Number(samplingEventId))
      ? `/sampling-event/${samplingEventId}`
      : null
  );
  const { data: farm, isLoading: isLoadingFarm, error: errorFarm } = useGet(
    samplingEvent?.farm ? `/farm/${samplingEvent.farm}` : null
  );

  return {
    isLoading: isLoadingSamplingEvent || isLoadingFarm,
    error: errorSamplingEvent || errorFarm,
    samplingEvent,
    type: getSamplingEventType(samplingEvent),
    farm,
    title: getSamplingEventTitle(samplingEvent, farm),
    mutate,
  };
};
