import React from "react";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import classnames from "classnames";
import "./Section.scss";

export const Section = ({ isDone, children }) => {
  const classes = classnames("section-done-icon", {
    done: isDone,
  });
  return (
    <Card body className="mb-3">
      <div className="d-flex align-items-baseline">
        <div className="mr-3">
          <FontAwesomeIcon icon={faCheckCircle} size="2x" className={classes} />
        </div>
        <div>{children}</div>
      </div>
    </Card>
  );
};
