import React from "react";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { ReportMetadata } from "components/ReportMetadata";
import { RitchTextRenderer } from "components/RitchTextRenderer";
import { useGet } from "components/api/useGet";
import { StickyBarBottom } from "components/StickyBarBottom";
import { ActionSummaryReportTitle } from "../ActionSummaryReportTitle";
import { MacroMineralSupplementationTable } from "../MacroMineralSupplementationTable";
import { TraceMineralSupplementationTable } from "../TraceMineralSupplementationTable/TraceMineralSupplementationTable";
import "../index.scss";

export const ActionSummaryReportPage = () => {
  // Fetch report data
  const { samplingEventId } = useParams();
  const {
    data: reportRaw,
    isLoading: isLoadingReport,
    error: errorLoadingReport,
  } = useGet(
    `/reports/action-summary-report?sampling_event=${samplingEventId}`
  );
  const report = reportRaw?.results?.[0];
  const stockClasses = report?.prescriptions
    ? Object.keys(report?.prescriptions)
    : [];

  if (isLoadingReport) {
    return <div>Loading...</div>;
  }
  if (errorLoadingReport) {
    return <div>Failed to load Action Summary Report.</div>;
  }
  return (
    <div className="report">
      <ActionSummaryReportTitle samplingEventId={samplingEventId} />
      <ReportMetadata samplingEventId={samplingEventId} />

      <section>
        <h2>Macro Mineral Supplementation</h2>

        <h3 className="sr-only">Table</h3>
        <MacroMineralSupplementationTable
          stockClasses={stockClasses}
          samplingEventId={samplingEventId}
          report={report}
        />

        <h3>Comments</h3>
        <RitchTextRenderer html={report?.macro_supplementation_comment} />
      </section>

      <section>
        <h2>Trace Mineral Supplementation</h2>

        <h3 className="sr-only">Table</h3>
        <TraceMineralSupplementationTable
          stockClasses={stockClasses}
          samplingEventId={samplingEventId}
          report={report}
        />

        <h3>Comments</h3>
        <RitchTextRenderer html={report?.trace_supplementation_comment} />
      </section>

      <StickyBarBottom>
        <Button onClick={() => window.print()}>Print</Button>
      </StickyBarBottom>
    </div>
  );
};
