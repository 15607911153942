import React from "react";
import PropTypes from "prop-types";
import { SensitivityFactorForm } from "./SensitivityFactorForm";
import uiSchema from "./uiSchema.json";

export const YoungstockForm = ({
  samplingEvent,
  stockClass,
  analysis,
  schema,
}) => {
  const uiSchemaSet = samplingEvent.beef
    ? uiSchema.beef_youngstock
    : uiSchema.dairy_youngstock;
  return (
    <SensitivityFactorForm
      samplingEvent={samplingEvent}
      stockClass={stockClass}
      analysis={analysis}
      analysisType="youngstock_dietary_analysis"
      uiSchemaSet={uiSchemaSet}
      fieldSchemaAll={schema.youngstock_dietary_analysis.child.children}
    />
  );
};

YoungstockForm.propTypes = {
  stockClass: PropTypes.string.isRequired,
  analysis: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
};
