import React from "react";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";
import { Section } from "./Section";

export const QuestionnaireSection = ({ samplingEvent }) => (
  <Section isDone={true}>
    <h2>Questionnaire</h2>
    <p>
      View questionnaire from the following link. To edit, go to the view page
      and click "Edit" button in the bottom.
    </p>
    <Alert variant="warning">
      <strong>Caution:</strong> Editing the questionnaire might affect Dietary
      Analysis calculation.
    </Alert>
    <Link to={`/sampling-events/${samplingEvent.id}/questionnaire`}>
      View questionnaire
    </Link>
  </Section>
);

QuestionnaireSection.propTypes = {
  samplingEvent: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};
