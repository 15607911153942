import React from "react";
import ClipboardJS from "clipboard";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";

new ClipboardJS(".clipboard");

export const CopyPhraseButton = ({ phrase }) => (
  <OverlayTrigger placement="bottom" overlay={<Tooltip>Copy phrase</Tooltip>}>
    <Button
      type="button"
      size="sm"
      variant="light"
      className="clipboard float-right"
      data-clipboard-text={phrase}
    >
      <FontAwesomeIcon icon={faCopy} />
    </Button>
  </OverlayTrigger>
);
