import React from "react";
import PropTypes from "prop-types";
import { useSamplingEvent } from "components/api/useSamplingEvent";
import { getDisplayDate } from "services";
import "./index.scss";

export const ReportMetadata = ({ samplingEventId }) => {
  const {
    isLoading,
    samplingEvent,
    farm,
  } = useSamplingEvent(samplingEventId);
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <div className="d-flex flex-wrap justify-content-end mb-2">
        <div className="d-flex">
          <div className="metadata-label mr-3">Client</div>
          <div>
            {Array.isArray(farm.clients) &&
              farm.clients.map((client) => (
                <div key={client.id}>
                  {client.first_name} {client.last_name}
                </div>
              ))}
          </div>
        </div>
        <div className="d-flex ml-4">
          <div className="metadata-label mr-3">Farm</div>
          <div>{farm.name}</div>
        </div>
        <div className="d-flex ml-4">
          <div className="metadata-label mr-3">Sampling event date</div>
          <div>{getDisplayDate(samplingEvent.date)}</div>
        </div>
      </div>
    </>
  );
};

ReportMetadata.propTypes = {
  samplingEventId: PropTypes.string.isRequired,
};
