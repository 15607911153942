import React from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { useFormContext } from "react-hook-form";
import { FormFieldWrapper } from "./FormFieldWrapper";

export const FormFields = ({
  uiSchemaSet,
  fieldSchemaAll,
  defaultValueAll,
  inline,
}) => {
  const { errors } = useFormContext();

  const OuterWrapper = inline ? Form.Row : React.Fragment;
  return (
    <OuterWrapper>
      {uiSchemaSet.map((uiSchema, index) => {
        let fieldName = "";
        let fieldSchema = undefined;
        if (typeof uiSchema === "string") {
          fieldName = uiSchema;
        } else {
          fieldName = uiSchema.name;
        }
        fieldSchema = fieldSchemaAll?.[fieldName];
        const defaultValue = defaultValueAll?.[fieldName];
        const fieldErrors = errors?.[fieldName];

        const hidden = uiSchema?.ui?.hidden === true;
        const InnerWrapper = inline && !hidden ? Col : React.Fragment;
        const innerWrapperProps = inline && !hidden ? { xs: 12, md: true } : {};

        return (
          <InnerWrapper key={index} {...innerWrapperProps}>
            <FormFieldWrapper
              fieldName={fieldName}
              fieldSchema={fieldSchema}
              uiSchema={uiSchema}
              defaultValue={defaultValue}
              errors={fieldErrors}
            />
          </InnerWrapper>
        );
      })}
    </OuterWrapper>
  );
};

FormFields.propTypes = {
  uiSchemaSet: PropTypes.array.isRequired,
  fieldSchemaAll: PropTypes.object.isRequired,
  defaultValueAll: PropTypes.object,
  inline: PropTypes.bool,
};
