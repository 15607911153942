import React from "react";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { useAuth } from "./useAuth";

export const LogoutButton = () => {
  const history = useHistory();
  const auth = useAuth();
  const logout = async () => {
    await auth.logout();
    history.push("/login");
  };

  return (
    auth.session && (
      <Button variant="outline-secondary" onClick={logout}>
        Log out
      </Button>
    )
  );
};
