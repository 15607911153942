import React from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";

export const TraceElements = ({ reportData, stockCountUnit }) => {
  const trace = [
    reportData.co_ppm,
    reportData.cu_ppm,
    reportData.i_ppm,
    reportData.fe_ppm,
    reportData.mn_ppm,
    reportData.mo_ppm,
    reportData.se_ppm,
    reportData.zn_ppm,
  ];
  return (
    <>
      <h2>Trace Elements</h2>
      <Table hover size="sm" responsive className="element-analysis-table">
        <thead>
          <tr>
            <th></th>
            <th>Aggregate</th>
            <th>Total Ingested (mg/{stockCountUnit}/d)</th>
            <th>CoA</th>
            <th>Absorbed Units (mg/{stockCountUnit}/d)</th>
            <th>Required Levels (mg/{stockCountUnit}/d)</th>
            <th>Status</th>
            <th>Element Required (mg/{stockCountUnit}/d)</th>
            <th>%Suff/Def</th>
          </tr>
        </thead>
        <tbody>
          {trace.map((element) => {
            return (
              element && (
                <tr key={element.label}>
                  <th className="text-left">{element.label}</th>
                  <td>{element.aggregate_value.toFixed(2)}</td>
                  <td>{element.total_ingested.toFixed(2)}</td>
                  <td>{element.coa.toFixed(2)}</td>
                  <td>{element.absorbed_units.toFixed(2)}</td>
                  <td>{element.required_levels.toFixed(2)}</td>
                  <td>{element.status.toFixed(2)}</td>
                  <td>
                    {element.element_required?.supplement_value?.toFixed(2)}
                  </td>
                  <td>{element.percent_suffdeff?.toFixed(2) || 'Err'} %</td>
                </tr>
              )
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

TraceElements.propTypes = {
  reportData: PropTypes.object.isRequired,
};
