import { useEffect } from "react";
import useSWR from "swr";
import { api } from "./api";
import { useAlert } from "components/alert/useAlert";

const fetcher = (path) =>
  api(path, {
    method: "OPTIONS",
  }).then((res) => res.data);

export function useSchema(path) {
  const { data, error } = useSWR(path, fetcher);
  const alert = useAlert();
  useEffect(() => {
    if (error) {
      alert.set({
        message: "Failed to connect to the server. Please try again later.",
        variant: "danger",
      });
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return {
    schema: data?.actions?.POST,
    isLoading: !error && !data,
    error,
  };
}
