import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { FarmSelect } from "./FarmSelect";
import { TypeSelect } from "./TypeSelect";
import { QuestionnaireDairyForm } from "components/forms/QuestionnaireDairyForm";
import { QuestionnaireBeefForm } from "components/forms/QuestionnaireBeefForm";
import { QuestionnaireSheepForm } from "components/forms/QuestionnaireSheepForm";
import { useGet } from "components/api/useGet";

export const NewSamplingEventPage = () => {
  const [selectedFarmId, setSelectedFarmId] = useState("");
  const [samplingType, setSamplingType] = useState("");
  const [showForm, setShowForm] = useState(false);
  const { data: farm } = useGet(
    selectedFarmId ? `/farm/${selectedFarmId}` : null
  );

  useEffect(() => {
    setSamplingType("");
  }, [selectedFarmId]);

  return (
    <React.Fragment>
      {!showForm && (
        <>
          <h1>Create a New Sampling Event</h1>

          <section className="mb-4">
            <FarmSelect
              selected={selectedFarmId}
              setFarmId={setSelectedFarmId}
            />
          </section>

          {selectedFarmId && (
            <section className="mb-4">
              <TypeSelect
                farmId={selectedFarmId}
                selected={samplingType}
                setType={setSamplingType}
              />
            </section>
          )}

          <Button
            onClick={() => {
              setShowForm(true);
            }}
            disabled={!samplingType}
          >
            Create
          </Button>
        </>
      )}

      {showForm && (
        <>
          <h1>
            {farm?.name} / {samplingType === "dairy" && "Dairy Cow"}
            {samplingType === "beef" && "Drystock Beef"}
            {samplingType === "sheep" && "Drystock Sheep"}
          </h1>

          {selectedFarmId && samplingType === "dairy" && (
            <QuestionnaireDairyForm farmId={selectedFarmId} isEditMode />
          )}
          {selectedFarmId && samplingType === "beef" && (
            <QuestionnaireBeefForm farmId={selectedFarmId} isEditMode />
          )}
          {selectedFarmId && samplingType === "sheep" && (
            <QuestionnaireSheepForm farmId={selectedFarmId} isEditMode />
          )}
        </>
      )}
    </React.Fragment>
  );
};
