import React from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import { getDisplayDate } from "services";
import "./index.scss";

export const Metadata = ({ samplingEvent, data }) => {
  return (
    <Table borderless size="sm" className="farm-info-table">
      <tbody>
        <tr>
          <th>Farm</th>
          <td>{data.farm}</td>
        </tr>
        <tr>
          <th>Sampling event date</th>
          <td>{getDisplayDate(samplingEvent.date)}</td>
        </tr>
        <tr>
          <th>Lab analysis date</th>
          <td>{getDisplayDate(data.sampling_date)}</td>
        </tr>
        <tr>
          <th>Lab analysis job number</th>
          <td>{data.sampling_job_number}</td>
        </tr>
      </tbody>
    </Table>
  );
};

Metadata.propTypes = {
  farm: PropTypes.string,
  sampling_date: PropTypes.string,
  last_edited: PropTypes.string,
  milk_production: PropTypes.string,
  percentage_milk_solids: PropTypes.number,
  kg_ms: PropTypes.number,
  average_body_weight: PropTypes.number,
};
