import React from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { LogoutButton } from "components/auth/LogoutButton";
import { ChangePasswordLink } from "components/auth/ChangePasswordLink";
import { AlertBanner } from "components/alert/AlertBanner";
import { Breadcrumb } from "components/Breadcrumb";
import { PageTitle } from "components/PageTitle";
import "./index.scss";
import logo from "assets/logo-wide.svg";

export const PageFrame = ({ children }) => {
  return (
    <div className="page-frame">
      <PageTitle />

      <header>
        <Container fluid>
          <div className="d-flex justify-content-between align-items-center">
            <div className="app-name d-flex align-items-center">
              <Link to="/" aria-label="Home">
                <img src={logo} alt="" className="logo" />
              </Link>
            </div>
            <div className="user-menu">
              <div className="d-flex align-items-baseline">
                <ChangePasswordLink />
                <LogoutButton />
              </div>
            </div>
          </div>
        </Container>
      </header>

      <main>
        <Container fluid="lg">
          <AlertBanner />
          <Breadcrumb />
          {children}
        </Container>
      </main>

      {/* Footer not needed for now */}
      {/* <footer>
        <Container fluid>Footer</Container>
      </footer>  */}
    </div>
  );
};
