import React from "react";
import { Switch, Route, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSamplingEvent } from "components/api/useSamplingEvent";
import { useGet } from "components/api/useGet";

const SamplingEventTitle = () => {
  const { samplingEventId } = useParams();
  const { isLoading, title: samplingEventTitle } = useSamplingEvent(
    samplingEventId
  );
  return isLoading ? null : (
    <>
      <Helmet>
        <title>{samplingEventTitle}</title>
      </Helmet>
      <Route path="/sampling-events/:samplingEventId/questionnaire">
        <Helmet>
          <title>Questionnaire | {samplingEventTitle}</title>
        </Helmet>
      </Route>
      <Route path="/sampling-events/:samplingEventId/analysis">
        <Helmet>
          <title>Dietary Analysis Report | {samplingEventTitle}</title>
        </Helmet>
      </Route>
      <Route path="/sampling-events/:samplingEventId/commentary">
        <Helmet>
          <title>Dietary Commentary Report | {samplingEventTitle}</title>
        </Helmet>
      </Route>
      <Route path="/sampling-events/:samplingEventId/action">
        <Helmet>
          <title>Action Summary Report | {samplingEventTitle}</title>
        </Helmet>
      </Route>
    </>
  );
};

const FarmTitle = () => {
  const { farmId } = useParams();
  const { data: farm, isLoading } = useGet(
    !Number.isNaN(Number(farmId)) ? `/farm/${farmId}` : null
  );
  return isLoading || !farm ? null : (
    <Helmet>
      <title>{farm.name}</title>
    </Helmet>
  );
};

export const PageTitle = () => {
  return (
    <>
      <Helmet
        defaultTitle="DietDecoder"
        titleTemplate="%s | DietDecoder"
      ></Helmet>
      <Switch>
        <Route path="/login">
          <Helmet>
            <title>Login</title>
          </Helmet>
        </Route>

        <Route path="/sampling-events/new">
          <Helmet>
            <title>Create a New Sampling Event</title>
          </Helmet>
        </Route>
        <Route path="/sampling-events/:samplingEventId">
          <SamplingEventTitle />
        </Route>
        <Route exact path="/sampling-events">
          <Helmet>
            <title>Sampling Events</title>
          </Helmet>
        </Route>

        <Route path="/farms/new">
          <Helmet>
            <title>Add a New Farm</title>
          </Helmet>
        </Route>
        <Route path="/farms/:farmId">
          <FarmTitle />
        </Route>
        <Route exact path="/farms">
          <Helmet>
            <title>Farms</title>
          </Helmet>
        </Route>
      </Switch>
    </>
  );
};
