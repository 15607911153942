import React, { useState, useContext, createContext } from "react";
// import { useLocation } from "react-router-dom";

export const alertContext = createContext();

// Provider component that wraps your app and makes alert object ...
// ... available to any child component that calls useAlert().
export function ProvideAlert({ children }) {
  const alert = useProvideAlert();
  return (
    <alertContext.Provider value={alert}>{children}</alertContext.Provider>
  );
}

// Hook for child components to get the alert object ...
// ... and re-render when it changes.
export const useAlert = () => {
  return useContext(alertContext);
};

// TODO: Solve alert dependency issue
// It complains not having alert as a dependency in useEffect
// but alert.clear and alert.set changes on every render
// so this effect runs when user dismisses alert, causing the alert to be set again.
// A possible solution is to make it run only apiState is changed
// using this: https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
// Probably, hook in context is wrong. Context is for state mgmt in the first place and shouldn't need hooks.
// Or put it in JSX, like react-helmet?
function useProvideAlert() {
  const [message, setMessage] = useState(undefined);
  const [variant, setVariant] = useState("success");
  const [show, setShow] = useState(false);
  // const { pathname } = useLocation();

  function set({ message, variant = "success" }) {
    setMessage(message);
    setVariant(variant);
    setShow(true);
    window.scrollTo(0, 0);
  }

  function clear() {
    setMessage(undefined);
    setVariant("success");
    setShow(false);
  }

  return {
    message,
    variant,
    show,
    set,
    clear,
  };
}
