import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { mutate } from "swr";
import { useForm, FormProvider } from "react-hook-form";
import deepEqual from "deep-equal";
import { FormFields } from "components/forms/FormFields";
import { useAlert } from "components/alert/useAlert";
import { useGet } from "components/api/useGet";
import { useSchema } from "components/api/useSchema";
import { useApi } from "components/api/useApi";
import { handleApiError } from "services";
import { PRODUCT_ELEMENT_MAP } from "services/constants"
import uiSchema from "./uiSchema.json";

// Get percentage of each element in the product
// Filter to only elements with values
const getElementPercentage = (productLookup) => {
  const percentage = {};
  PRODUCT_ELEMENT_MAP.forEach((element) => {
    if (productLookup[element.key]) {
      percentage[element.symbol] = productLookup[element.key];
    }
  });
  return percentage;
};

export const PrescriptionForm = ({ analysisData }) => {
  const { schema, isLoading: isLoadingSchema } = useSchema("/prescription");
  const { data: products, isLoading: isLoadingProducts } =
    useGet("/mineral-product");
  const { data: prescription, isLoading: isLoadingPrescription } = useGet(
    analysisData?.prescription
      ? `/prescription/${analysisData.prescription}`
      : null
  );

  // Override product choices
  const productChoices = products?.results?.map((product) => ({
    value: product.id,
    display_name: product.name,
  }));
  uiSchema.prescription[1].items[1].ui.choices = productChoices;

  const formMethods = useForm();
  const watchPrescription = formMethods.watch("products");
  const [calc, setCalc] = useState();
  const [initialised, setInitialised] = useState(false);
  // Calculate form text values (i.e. amount of elements)
  useEffect(() => {
    if (!prescription || !Array.isArray(watchPrescription)) return null;
    if (
      !initialised &&
      watchPrescription.length < prescription.products.length
    ) {
      // Don't update calculations while setting up field array
      // Because this will break the field array
      return null;
    }
    setInitialised(true);

    const newCalc = watchPrescription.map((formData) => {
      const product = products?.results?.find(
        (prod) => prod.id === Number(formData.product)
      );
      if (!product) return formData;
      const element_percentage = getElementPercentage(product);

      // Store all elements' amount in mg, and calc g on display
      const element_amount_mg = {};
      product &&
        Object.keys(element_percentage).forEach((el) => {
          element_amount_mg[el] =
            (formData.amount * element_percentage[el]) / 100;
          if (formData?.unit === "g") {
            element_amount_mg[el] *= 1000;
          }
          if (el === "Mg" && element_percentage["Mg CoA"]) {
            element_amount_mg[el] *= element_percentage["Mg CoA"];
          }
        });

      // Cost currently missing in the lookup table
      // const cost_kg =
      //   product && typeof product.cost_kg === "number" ? product.cost_kg : 0;

      // let cost_dose = 0;
      // if (product && typeof formData.product_amount === "number") {
      //   if (product?.unit === "g") {
      //     cost_dose = (product.cost_kg * formData.product_amount) / 1000;
      //   } else {
      //     cost_dose = (product.cost_kg * formData.product_amount) / 1000000;
      //   }
      // }
      return {
        ...formData,
        element_percentage,
        element_amount_mg,
        // cost_kg,
        // cost_dose,
      };
    });
    // only when the values are not equal to the previous values, or it will infinite loop
    if (!deepEqual(calc, newCalc)) {
      setCalc(newCalc);
    }
  }, [watchPrescription, calc, products, initialised, prescription]);

  // Add text field values to the form data
  const [values, setValues] = useState();
  useEffect(() => {
    if (!prescription) return null;
    // Initial values should come from fetched data
    let products = prescription.products;
    // When the field array is initialised and calculation is updated, replace it with this
    if (calc?.length > 0) {
      products = calc.map((calc) => {
        const element_percentage =
          calc?.element_percentage &&
          Object.keys(calc.element_percentage).map((el) => (
            <div key={el}>
              {el}: {calc.element_percentage[el]} {el !== "Mg CoA" && "%"}
            </div>
          ));

        const element_amount =
          calc.element_amount_mg &&
          calc.unit &&
          Object.keys(calc.element_amount_mg).map((el) => {
            // Mg CoA is a variable for Mg calculation, so not needed in amount column
            if (el === "Mg CoA") return null;
            const amountUnitConverted =
              calc.unit === "g"
                ? calc.element_amount_mg[el] / 1000
                : calc.element_amount_mg[el];
            return (
              <div key={el}>
                {el}: {amountUnitConverted.toPrecision(4)} {calc.unit}
              </div>
            );
          });

        // const cost_kg = `$ ${calc.cost_kg}`;
        // const cost_dose = `$ ${calc.cost_dose.toPrecision(3)}`;

        return {
          ...calc,
          // prescription: prescription.id,
          element_percentage,
          element_amount,
          // cost_kg,
          // cost_dose,
        };
      });
    }
    const newValues = {
      id: prescription.id,
      products,
      // total_cost:
      //   calc?.length > 0 &&
      //   `$ ${calc.reduce((acc, cur) => acc + cur.cost_dose, 0).toPrecision(3)}`,
    };
    // only when the values are not equal to the previous values, or it will infinite loop
    if (!deepEqual(values, newValues)) {
      setValues(newValues);
    }
  }, [prescription, calc, values]);

  const [apiState, setRequest] = useApi();
  const onSubmit = (formData) => {
    const data = { ...formData };
    alert.clear();
    setRequest({
      path: `/prescription/${prescription.id}`,
      options: {
        method: "PUT",
        data,
      },
    });
  };

  const alert = useAlert();
  useEffect(() => {
    if (apiState.isLoading) {
      return;
    } else if (apiState.isError) {
      handleApiError({ apiState, alert });
    } else if (apiState.data?.id) {
      alert.set({
        message: "Prescription is updated.",
        variant: "success",
      });
      mutate(`/prescription/${analysisData.prescription}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiState]);

  const isLoading =
    isLoadingSchema || isLoadingProducts || isLoadingPrescription;
  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <FormProvider {...formMethods}>
      <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <FormFields
          uiSchemaSet={uiSchema.prescription}
          fieldSchemaAll={schema}
          defaultValueAll={values}
        />
        <Button type="submit">Update prescription</Button>
      </Form>
    </FormProvider>
  );
};
