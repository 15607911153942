import { useState, useEffect, useReducer } from "react";
import { api } from "./api";

// Read this for data fetching with React Hooks
// https://www.robinwieruch.de/react-hooks-fetch-data
const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_INIT":
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: action.payload,
      };
    default:
      throw new Error("useApi wrong action.type");
  }
};

export const useApi = (initialRequest, initialData = undefined) => {
  const [request, setRequest] = useState(initialRequest);

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    data: initialData,
  });

  useEffect(() => {
    if (!request) {
      return;
    }
    let didCancel = false;
    const fetchData = async () => {
      dispatch({ type: "FETCH_INIT" });
      try {
        const response = await api(request.path, {
          ...request.options,
        });
        if (didCancel) {
          return;
        }
        dispatch({ type: "FETCH_SUCCESS", payload: response.data });
      } catch (error) {
        // Axios takes care of erroneous status code and throws an error.
        if (!didCancel) {
          if (error.response) {
            dispatch({ type: "FETCH_FAILURE", payload: error.response });
          } else {
            dispatch({ type: "FETCH_FAILURE", payload: error });
          }
        }
      }
    };

    fetchData();

    return () => {
      didCancel = true;
    };
  }, [request]);

  return [state, setRequest];
};
