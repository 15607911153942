import React from "react";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { ReportMetadata } from "components/ReportMetadata";
import { useGet } from "components/api/useGet";
import { useSamplingEvent } from "components/api/useSamplingEvent";
import { RitchTextRenderer } from "components/RitchTextRenderer";
import { getSeason } from "services";
import { StickyBarBottom } from "components/StickyBarBottom";
import { DietaryBreakdownTable } from "../DietaryBreakdownTable";
import { ReportSummaryTable } from "../ReportSummaryTable";
import { KeyDietaryScale, KeyDietaryScaleNonmineral } from "../KeyDietaryScale";
import { NonMinerals } from "../NonMinerals";
import { MacroMinerals } from "../MacroMinerals";
import { TraceMinerals } from "../TraceMinerals";
import "../index.scss";

export const DietaryCommentaryReportPage = () => {
  const { samplingEventId } = useParams();
  const {
    isLoading: isLoadingSe,
    samplingEvent,
    type: samplingEventType,
  } = useSamplingEvent(samplingEventId);
  const { data: reportRaw, isLoading: isLoadingReport } = useGet(
    `/reports/dietary-commentary-report?sampling_event=${samplingEventId}`
  );
  const report = reportRaw?.results?.[0];

  const isLoading = isLoadingSe || isLoadingReport;
  return isLoading ? (
    <div>Loading...</div>
  ) : !report ? (
    <div>Dietary Commentary Report for this sampling event does not exist.</div>
  ) : (
    <div className="report">
      <h1>Dietary Commentary for {getSeason(samplingEvent.date)}</h1>

      <ReportMetadata samplingEventId={samplingEventId} />

      <DietaryBreakdownTable
        report={report}
        samplingEventType={samplingEventType}
      />

      <section>
        <h2>Report Summary</h2>
        <ReportSummaryTable report={report} />
      </section>

      <section>
        <h2>Non-Mineral Dietary Composition</h2>

        <KeyDietaryScaleNonmineral />

        <div className="two-columns">
          <h3>Background</h3>
          <RitchTextRenderer html={report.nonmineral_background} />

          <NonMinerals report={report} samplingEventType={samplingEventType} />
        </div>
      </section>

      <section>
        <h2>Macro Minerals Profile</h2>

        <KeyDietaryScale />

        <div className="two-columns">
          <h3>Background</h3>
          <RitchTextRenderer html={report.macro_background} />

          <MacroMinerals
            report={report}
            samplingEventType={samplingEventType}
          />
        </div>
      </section>

      <section>
        <h2>Trace Minerals Profile</h2>

        <KeyDietaryScale />

        <div className="two-columns">
          <h3>Background</h3>
          <RitchTextRenderer html={report.trace_background} />

          <TraceMinerals
            report={report}
            samplingEventType={samplingEventType}
          />
        </div>
      </section>

      <StickyBarBottom>
        <Button onClick={() => window.print()}>Print</Button>
      </StickyBarBottom>
    </div>
  );
};
