import React from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";

export const DietaryData = ({ report }) => {
  if (
    !report ||
    !report.diet_sources ||
    Object.keys(report.diet_sources).length <= 0 ||
    !report.dm_intake_total
  ) {
    console.error("DM intake is missing in the report data.");
    return <div>Error: DM intake is missing in the report data.</div>;
  }

  return (
    <Table borderless size="sm" className="dietary-data-table">
      <thead>
        <tr>
          <th>Dietary Blend</th>
          <th>DM Intake (kg/d)</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(report.diet_sources).map((key) => (
          <tr key={key}>
            <td>{report.diet_sources[key].name}</td>
            <td>{report.diet_sources[key].dm_intake.toFixed(2)}</td>
          </tr>
        ))}
        <tr>
          <th>Total DM</th>
          <th>{report.dm_intake_total.toFixed(2)}</th>
        </tr>
      </tbody>
    </Table>
  );
};

DietaryData.propTypes = {
  report: PropTypes.object.isRequired,
};
