import React from "react";
import PropTypes from "prop-types";
import { SensitivityFactorForm } from "./SensitivityFactorForm";
import uiSchema from "./uiSchema.json";

export const SheepForm = ({ samplingEvent, stockClass, analysis, schema }) => {
  return (
    <SensitivityFactorForm
      samplingEvent={samplingEvent}
      stockClass={stockClass}
      analysis={analysis}
      analysisType="sheep_dietary_analysis"
      uiSchemaSet={uiSchema.sheep}
      fieldSchemaAll={schema.sheep_dietary_analysis.child.children}
    />
  );
};

SheepForm.propTypes = {
  samplingEvent: PropTypes.object.isRequired,
  stockClass: PropTypes.string.isRequired,
  analysis: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
};
