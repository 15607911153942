import React from "react";
import { PrescriptionTable } from "./PrescriptionTable";
import { PrescriptionForm } from "./PrescriptionForm";

export const Prescription = ({ reportData, analysisData, DMI }) => {
  return (
    <>
      <h2>Prescription</h2>
      <PrescriptionTable reportData={reportData} analysisData={analysisData} DMI={DMI} />
      <PrescriptionForm analysisData={analysisData} />
    </>
  );
};
